import React, { useEffect, useState } from 'react';
import { gridTheme } from '@vodafoneziggo/sandwich';
import { HideProps } from './hide.types';

export const Hide = ({ children, down, up }: HideProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHidden, setIsHidden] = useState(false);
  const breakpoints = Object.fromEntries(Object.entries(gridTheme.breakpoints).sort(([, pxA], [, pxB]) => pxA - pxB));

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (down) {
      setIsHidden(windowWidth < down);
    }

    if (up) {
      setIsHidden(windowWidth >= up);
    }
  }, [windowWidth, down, up, breakpoints]);

  if (isHidden) return null;

  return <>{children}</>;
};

Hide.displayName = 'Hide';
