import React from 'react';
import { useHistory } from 'react-router';
import { useUser } from 'hooks/useUser';

import { useInitializeChat } from './Chat.hooks';
import './dimelo-chat-helper';

const DIMELO_ID = 'dimelo_chat_item_markup_b4f9b0a7f859ef6e8cb7e296';

export const Chat: React.FC = () => {
  const { userState } = useUser();
  const history = useHistory<History>();

  useInitializeChat(history);

  const { enterprise, user } = userState;

  const currentUser = user as any;
  const activeContext = enterprise?.activeContext;

  if (!user || !activeContext || !currentUser.features.enterprise.support.chat) {
    return null;
  }

  return (
    <>
      <div className="dimelo_chat_item_markup" id={DIMELO_ID} />
      <vf-chat
        section="myvodafone chatbot"
        customertype={activeContext.chat_segment_code || 'consumer'}
        screenname={user.contact.full_name}
        firstName={user.contact.first_name}
        lastName={user.contact.last_name}
        email={user.contact.email}
        msisdn={user.contact.primary_phone}
        role={activeContext.role}
        customerid={activeContext.id}
      />
    </>
  );
};
