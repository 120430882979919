import styled, { Breakpoint, Spacing, Color } from '@vodafoneziggo/sandwich/theme';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { Icon } from '@vodafoneziggo/sandwich';
import { down } from 'styles/utils/mediaqueries';

export const Header = styled.div`
  height: 8rem;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding-top: ${Spacing.XS};
`;

export const SubHeader = styled.div<{ hasSubMenu?: boolean }>`
  background-color: ${(props) => (props.hasSubMenu ? props.theme.colors.grayD40 : Color.WHITE)};
  color: ${(props) => props.theme.colors.white};

  ${down(Breakpoint.SM)`
    display: none;
  `}
`;

export const WideContainer = styled(Container)`
  padding-left: ${Spacing.M};
  padding-right: ${Spacing.M};
`;

export const TopRow = styled(Row)`
  margin-bottom: ${Spacing.XS};
  justify-content: flex-end;

  ${down(Breakpoint.SM)`
    display: flex;
    flex-direction: column;
    line-height: 1.6rem;
  `}
`;

export const MobileMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.S};
`;

export const MenuRow = styled(Row)`
  ${down(Breakpoint.SM)`
    display: none;
  `}
`;

export const LogoCol = styled(Col)`
  padding: 0 ${Spacing.XS};
`;

export const MenuCol = styled(Col)`
  padding: 0;
`;

export const PaymentHeader = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: ${Spacing.S} ${Spacing.XL};
`;

export const PaymentLogoCol = styled(Col)`
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
`;

export const PaymentSecureCol = styled(Col)`
  text-align: right;
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.grayD10};
`;

export const PaymentHeaderIcon = styled(Icon)`
  margin-right: ${Spacing.XS};
`;

export const LogoAnchor = styled.a`
  color: ${(props) => props.theme.colors.white};
`;
