import { EnterpriseContextType, IEnterpriseContext } from '@vodafoneziggo/sandwich/models/user.model';
import { history } from 'utils/history';
import { IMenuItem } from 'components/Header/hooks/useMenu.types';
import { MAIN_USER_ROLES } from 'config/permissions/main-user-roles.types';
import { CONTEXT_ROLES } from 'config/permissions/context-roles.types';
import { V2Config } from 'containers/Other/FrontendConfigs.container';
import { some } from './permissions';

export const getUrlBase = (context: IEnterpriseContext) => {
  if (context.role === CONTEXT_ROLES.END_USER) {
    return `/medewerker/${context.id}`;
  }

  return `/v2/${context.type === EnterpriseContextType.ACCOUNT ? 'account' : 'organisatie'}/${context.id}`;
};

export const getUrlBaseManagement = (context: IEnterpriseContext) => {
  return `/v2/${
    context.type === EnterpriseContextType.ACCOUNT ? `account/${context.id}` : `organisatie/${context.id}/beheer`
  }`;
};

export const getLegacyUrlBase = (context: IEnterpriseContext) => {
  if (context.role === CONTEXT_ROLES.END_USER) {
    return `/medewerker/${context.id}`;
  }

  return `/${context.type === EnterpriseContextType.ACCOUNT ? 'account' : 'organisatie'}/${context.id}`;
};

export const isChooseContextPage = () => {
  return /\/maak-een-keuze($|\/|\?)/.test(window.location.href);
};

export const redirectToChooseContext = () => {
  if (isChooseContextPage()) {
    // Don't create an infinite redirect loop :)
    return;
  }

  history.push('/maak-een-keuze');
};

export const redirectToMyConsumer = () => {
  // Needs to be a full page reload
  window.location.assign('/my');
};

/**
 * Returns the url based on v2_url configuration in legacy portal, will fallback to legacy url when correct
 * state is not enabled in frontend-configs
 */
export const getMenuItemUrl = (context: IEnterpriseContext, v2Config: V2Config, menuItem: IMenuItem) => {
  const url =
    menuItem.stateObj.data.v2_url && v2Config.enabled.includes(menuItem.state)
      ? `/v2${menuItem.stateObj.data.v2_url}`
      : menuItem.stateObj.completeUrl;

  return url.replace(':context_id', context.id);
};

export const hasMenuItemPermission = (context: IEnterpriseContext, menuItem: IMenuItem) => {
  return !menuItem.hide && some(context.main_user_roles as MAIN_USER_ROLES[], menuItem.stateObj.allow_access);
};

export const redirectToLegacy = (path = '/') => {
  // Needs to be a full page reload
  window.location.assign(`/zakelijk/my${path}`);
};
