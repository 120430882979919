import React from 'react';
import { Icon } from '@vodafoneziggo/sandwich';
import {
  Modal as SandwichModal,
  ModalAnimateIn,
  ModalButtons,
  ModalContent,
  ModalDialog,
  ModalFooter,
  ModalSize,
  ModalTitle,
  ModalSubtitle,
} from '@vodafoneziggo/sandwich/components/modal';

interface ModalProps {
  dialogTestId?: string;
  headerTitle: string;
  headerIcon: {
    name: string;
    source: string;
  };
  footer?: React.ReactNode;
  hideCloseButton?: boolean;
  isVisible?: boolean;
  onClose?: () => void;
  onToggleOpen?: () => void;
  subTitle?: React.ReactNode;
  testId?: string;
  title?: string;
  titleTestId?: string;
  trigger?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  dialogTestId,
  headerIcon,
  headerTitle,
  footer,
  hideCloseButton = false,
  isVisible,
  onClose,
  onToggleOpen,
  subTitle,
  testId = 'modal',
  title,
  titleTestId = 'modalTitle',
  trigger,
}) => {
  return (
    <SandwichModal
      size={ModalSize.SMALL}
      testId={testId}
      onClose={onClose}
      isVisible={isVisible}
      onToggleOpen={onToggleOpen}>
      <ModalDialog
        testId={dialogTestId}
        headerIcon={<Icon icon={headerIcon} />}
        headerTitle={headerTitle}
        hideCloseButton={hideCloseButton}>
        <ModalAnimateIn>
          {title && <ModalTitle data-testid={titleTestId}>{title}</ModalTitle>}
          {subTitle && <ModalSubtitle>{subTitle}</ModalSubtitle>}
          <ModalContent>{children}</ModalContent>
        </ModalAnimateIn>
        {footer && (
          <ModalFooter>
            <ModalButtons>{footer}</ModalButtons>
          </ModalFooter>
        )}
      </ModalDialog>
      {trigger || null}
    </SandwichModal>
  );
};
