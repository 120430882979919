import { Button as SandwichButton } from '@vodafoneziggo/sandwich';
import styled, { Breakpoint, css, Spacing } from '@vodafoneziggo/sandwich/theme';
import { Col as GridCol } from 'styled-bootstrap-grid';
import { DropdownMenu as DropdownMenuComponent } from 'components/Dropdown/DropdownMenu/DropdownMenu';
import { down } from 'styles/utils/mediaqueries';
import { MenuIconProps } from './userInfo.types';

export const Col = styled(GridCol)`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
`;

export const Name = styled.div`
  margin-left: ${Spacing.XS};

  ${down(Breakpoint.SM)`  
    max-width: 10.5rem;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const MenuIcon = styled.div<MenuIconProps>`
  display: flex;
  align-items: center;
  transition: transform ${(props) => props.theme.transitions.default};

  ${(props) =>
    props.isRotated &&
    css`
      transform: scale(1, -1);
    `}
`;

export const DropdownMenu = styled(DropdownMenuComponent)`
  padding: ${Spacing.S};
`;

export const DropdownButton = styled(SandwichButton)`
  font-size: 0.875rem;
  height: 3rem;
  padding: ${Spacing.XS};
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
