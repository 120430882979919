import { useContext, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { UserContext } from 'context/user/user.context';

import { BillingCustomerResponse } from './billingCustomersList.types';
import { GetBillingCustomersQueryVariables, useGetBillingCustomersQuery } from './billing-customers.graphql.generated';

/**
 * Get the billing customers for the current account.
 *
 * @param filter the search term
 */
export const useBillingCustomers = (): BillingCustomerResponse => {
  const [variables, setVariables] = useState<GetBillingCustomersQueryVariables>();
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const { state: userState } = useContext(UserContext);

  const { loading, data } = useGetBillingCustomersQuery({ skip: !variables, variables });

  // Handles loading state of the endpoint.
  useEffect(() => {
    if (!loading && data) {
      setIsLoading(false);

      return;
    }

    setIsLoading(true);
  }, [loading, data]);

  // Handles query parameters.
  useEffect(() => {
    const accountId = userState.enterprise.activeContext?.account;

    if (!accountId || filter === undefined) {
      return;
    }

    setVariables({ accountId, query: filter });
  }, [userState.enterprise.activeContext, filter]);

  return {
    isLoading,
    setFilter,
    items: data?.billingCustomers?.items || [],
  };
};

export const BillingCustomersContainer = createContainer(useBillingCustomers);
