import React, { useContext } from 'react';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { NavLink } from 'react-router-dom';
import { Button } from '@vodafoneziggo/sandwich';
import { UserContext } from 'context/user/user.context';
import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { useTranslations } from 'components/Intl/useTranslations';
import { getUrlBase } from 'utils/location';
import * as S from './notFound.styles';

export const NotFound = () => {
  const { translate } = useTranslations('page.notFound');
  const {
    state: {
      enterprise: { activeContext },
    },
  } = useContext(UserContext);

  const href = activeContext ? `${getUrlBase(activeContext)}/overzicht` : '/';

  return (
    <S.Wrapper>
      <Container>
        <Row>
          <Col>
            <h1>{translate('content.message')}</h1>
            <NavLink data-testid="route-404-back-to-overview" to={href}>
              <Button variant={ButtonVariant.DARK} isOutlined>
                {translate('content.backToOverview')}
              </Button>
            </NavLink>
          </Col>
        </Row>
      </Container>
    </S.Wrapper>
  );
};
