import { Loader } from '@vodafoneziggo/sandwich/components/loading/loader/loader.styles';
import styled from '@vodafoneziggo/sandwich/theme';

export const Layout = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: ${(props) => props.theme.colors.grayD40};
`;

export const Wrapper = styled.div`
  margin-bottom: auto;
  background: ${(props) => props.theme.colors.grayL40};

  display: flex;
  flex-direction: column;
  /* Make this flex-item 100% height. This way we have a way of positioning
   * inner elements to the bottom of this container (e.g. Ziggo crosslink module) */
  flex-grow: 1;

  /* The React.Suspense fallback Loader is not contained within a LoadableComponent, so it doesn't inherit
   * its positioning. Here we add custom positioning for the loader, the reason it's fixed is because we
   * don't want to adjust for the height of the Header component, which causes the loader to not be
   * in the exact center of the page */
  > ${Loader} {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 400ms 50ms ease-in-out;
    opacity: 1;
  }
`;
