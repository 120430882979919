import { useRef, useEffect, useCallback } from 'react';
import { useIntervalCallback } from './useInterval.types';

/**
 * Calls a callback function with a set interval, in a React safe way.
 *
 * @param callback is the callback function to call. A 'clear' method is passed to clear the interval
 * @param delay is the interval delay
 * @param runImmediately indicates if the callback should be called immediately
 */
const useInterval = (callback: useIntervalCallback, delay: number, runImmediately = false) => {
  const savedCallback = useRef<useIntervalCallback>();
  const intervalId = useRef<number>();

  const clear = useCallback(() => clearInterval(intervalId.current), []);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current({ clear });
    }

    if (intervalId.current) clear();
    intervalId.current = window.setInterval(tick, delay);

    runImmediately && tick();

    return clear;
  }, [delay, runImmediately, clear]);
};

export default useInterval;
