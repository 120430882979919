import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSavedOrdersQueryVariables = Types.Exact<{
  accountId: Types.Scalars['String'];
  billingCustomerId: Types.Scalars['String'];
}>;


export type GetSavedOrdersQuery = (
  { __typename?: 'Query' }
  & { savedOrders: Array<(
    { __typename?: 'Order' }
    & Pick<Types.Order, 'itemCount'>
  )> }
);


export const GetSavedOrdersDocument = gql`
    query GetSavedOrders($accountId: String!, $billingCustomerId: String!) {
  savedOrders(accountId: $accountId, billingCustomerId: $billingCustomerId) {
    itemCount
  }
}
    `;

/**
 * __useGetSavedOrdersQuery__
 *
 * To run a query within a React component, call `useGetSavedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedOrdersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      billingCustomerId: // value for 'billingCustomerId'
 *   },
 * });
 */
export function useGetSavedOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetSavedOrdersQuery, GetSavedOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSavedOrdersQuery, GetSavedOrdersQueryVariables>(GetSavedOrdersDocument, options);
      }
export function useGetSavedOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSavedOrdersQuery, GetSavedOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSavedOrdersQuery, GetSavedOrdersQueryVariables>(GetSavedOrdersDocument, options);
        }
export type GetSavedOrdersQueryHookResult = ReturnType<typeof useGetSavedOrdersQuery>;
export type GetSavedOrdersLazyQueryHookResult = ReturnType<typeof useGetSavedOrdersLazyQuery>;
export type GetSavedOrdersQueryResult = Apollo.QueryResult<GetSavedOrdersQuery, GetSavedOrdersQueryVariables>;