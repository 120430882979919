import { useContext, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { EnterpriseContextType } from '@vodafoneziggo/sandwich/models';
import { UserContext } from 'context/user/user.context';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { GetSavedOrdersQueryVariables, useGetSavedOrdersQuery } from './saved-orders.graphql.generated';

const useSavedOrders = () => {
  const [variables, setVariables] = useState<GetSavedOrdersQueryVariables>();
  const [isLoading, setIsLoading] = useState(true);
  const { state: userState } = useContext(UserContext);
  // Make sure to skip, until the variables become available
  const { loading, data } = useGetSavedOrdersQuery({ skip: !variables, variables });
  const isHardwareOrderingEnabled = useFeatureFlag('hardwareOrdering', false);

  useEffect(() => {
    if (!loading && data) {
      // Make sure data is available when setting loading to false
      setIsLoading(false);

      return;
    }

    setIsLoading(true);
  }, [loading, data]);

  // Determine variables for the query from activeContext. This will also trigger the query when activeContext changes
  useEffect(() => {
    if (!isHardwareOrderingEnabled) {
      return;
    }

    // Orders are only available for customer context
    if (userState.enterprise.activeContext?.type !== EnterpriseContextType.CUSTOMER) {
      setIsLoading(false);

      return;
    }

    const billingCustomerId = userState.enterprise.activeContext?.id;
    const accountId = userState.enterprise.activeContext?.account;

    if (!billingCustomerId || !accountId) return;

    // Do the initial request, and whenever activeContext changes
    setVariables({ billingCustomerId, accountId });
  }, [userState.enterprise.activeContext, isHardwareOrderingEnabled]);

  return {
    data,
    isLoading,
  };
};

export const SavedOrdersContainer = createContainer(useSavedOrders);
