import React from 'react';
import { EnterpriseContextType } from '@vodafoneziggo/sandwich/models/user.model';
import { CONTEXT_ROLES } from 'config/permissions/context-roles.types';
import { ContextSwitcherIcon } from '../ContextSwitcherIcon/ContextSwitcherIcon';
import { ContextSwitcherIconIcon } from '../ContextSwitcherIcon/contextSwitcherIcon.types';
import { ContextSwitcherTopLevelIconProps } from './contextSwitcherTopLevelIcon.types';

export const ContextSwitcherTopLevelIcon = ({
  role,
  type,
  isIwr,
  mainUserBillingCustomers,
}: ContextSwitcherTopLevelIconProps) => {
  // IWR users
  // kerndepartement@iwr-az.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/account/6010101/overzicht
  if (isIwr) {
    return <ContextSwitcherIcon icon={ContextSwitcherIconIcon.IWR} />;
  }

  // End User (1 role)
  // wouter.eerdekens@qnh.be
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/medewerker/510001000/overzicht
  // ------------------- OR ----------------------
  // Single Billing Customer account with End User
  // End User role
  // harry.beckers@qnh.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/medewerker/510001000/overzicht
  if (role === CONTEXT_ROLES.END_USER) {
    return <ContextSwitcherIcon icon={ContextSwitcherIconIcon.Person} />;
  }

  // Regular Single Account with at least 2 and max 10 Billing Customers
  // Account role
  // bianca.schoonbroodt@qnh.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/account/1003/overzicht
  // ------------------- OR ----------------------
  // Regular Single Account with more than 10 Billing Customers
  // Account role
  // hen.snackers@qnh.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/account/1014/overzicht
  // ------------------- OR ----------------------
  // Regular Single Account with more than 10 Billing Customers
  // Billing Customer role
  // hen.snackers@qnh.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/organisatie/510001011/overzicht
  if (type === EnterpriseContextType.ACCOUNT) {
    return <ContextSwitcherIcon icon={ContextSwitcherIconIcon.TreeWithMarkedTop} />;
  }

  // Single Billing Customer account with End User
  // Single Billing Customer role
  // harry.beckers@qnh.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/organisatie/510001001/overzicht
  // ------------------- OR ----------------------
  // Single Billing Customer account (1 role)
  // mike.janssen@zuydermarkt.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/organisatie/517771000/overzicht
  // ------------------- OR ----------------------
  // Multi Account
  // Account role
  // eddy.mercks@qnq.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/account/1003/overzicht
  if (type === EnterpriseContextType.CUSTOMER && mainUserBillingCustomers === 1) {
    return <ContextSwitcherIcon icon={ContextSwitcherIconIcon.Building} />;
  }

  // Regular Single Account with at least 2 and max 10 Billing Customers
  // Billing Customer role
  // bianca.schoonbroodt@qnh.nl
  // https://kahuna-src.qnh.nl:8888/zakelijk/my/organisatie/510001000/overzicht
  if (type === EnterpriseContextType.CUSTOMER && mainUserBillingCustomers && mainUserBillingCustomers > 1) {
    return <ContextSwitcherIcon icon={ContextSwitcherIconIcon.TreeWithMarkedBottom} />;
  }

  return null;
};
