import React from 'react';
import { useSubmenu } from 'components/Header/hooks/useSubmenu';
import * as S from './Submenu.styles';

export const Submenu = () => {
  const { submenuItems, currentSubmenuItem, currentMenuItem } = useSubmenu();

  if (submenuItems.length === 0) return null;

  return (
    <S.Wrapper>
      {submenuItems.map((submenuItem) => (
        <div data-testid={`${currentMenuItem?.name}-${submenuItem.name}`} key={submenuItem.url}>
          <S.Link isActive={currentSubmenuItem === submenuItem} to={submenuItem.url} key={submenuItem.url}>
            {submenuItem.name}
          </S.Link>
        </div>
      ))}
    </S.Wrapper>
  );
};
