import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useContainer } from 'unstated-next';
import { UserContext } from 'context/user/user.context';
import { SearchTermContainer } from 'containers/Other/SearchTerm.container';
import { ContextSwitcherContainer } from 'components/Header/contextSwitcher.container';
import { BillingCustomer } from 'types.generated';

import { EnterpriseContextType } from '@vodafoneziggo/sandwich/models/user.model';
import { ContextDetails } from '../../ContextDetails/ContextDetails';
import { ContextSwitcherSearch } from '../../ContextSwitcherSearch/ContextSwitcherSearch';
import { ContextSwitcherSearchResult } from '../../ContextSwitcherSearchResult/ContextSwitcherSearchResult';
import { ContextSwitcherDropdownLevelIcon } from '../../ContextSwitcherDropdownLevelIcon/ContextSwitcherDropdownLevelIcon';
import * as S from '../contextSwitcher.styles';

import { BillingCustomersContainer } from './billingCustomers.container';
import { BillingCustomersListProps } from './billingCustomersList.types';

export const BillingCustomersList = ({ searchContainerRef }: BillingCustomersListProps) => {
  const { isSearchInitialState, setIsSearchInitialState } = useContainer(ContextSwitcherContainer);
  const { items, isLoading, setFilter } = useContainer(BillingCustomersContainer);
  const { mainUserBillingCustomers } = useContext(UserContext);

  const history = useHistory();

  // Reset the initial search state.
  useEffect(() => {
    return () => {
      setIsSearchInitialState(true);
    };
  }, [setIsSearchInitialState]);

  // Automatically apply focus to the search bar when the dropdown is opened.
  useEffect(() => {
    const searchBar = searchContainerRef.current?.querySelector('input');

    if (searchBar) {
      searchBar.focus();
    }
  }, [searchContainerRef]);

  /**
   * Filter available contexts based on a search term.
   */
  const filterContexts = useCallback(
    (searchTerm: string): void => {
      setFilter(searchTerm);
    },
    [setFilter]
  );

  /**
   * Handles switching of active context.
   */
  const onClickHandler = useCallback(
    (billingCustomerId: string) => {
      history.push(`/organisatie/${billingCustomerId}/overzicht`);
    },
    [history]
  );

  return (
    <SearchTermContainer.Provider>
      <div ref={searchContainerRef}>
        <ContextSwitcherSearch filterContexts={filterContexts} />
        {!isLoading && !isSearchInitialState && <ContextSwitcherSearchResult results={items.length} />}
      </div>
      <S.ContextContainer>
        {items?.length > 0 && (
          <S.ContextList>
            {items
              .filter((context: BillingCustomer) => context.type !== EnterpriseContextType.ACCOUNT)
              .sort((context_A: BillingCustomer, context_B: BillingCustomer) =>
                context_A.name.localeCompare(context_B.name)
              )
              .map((context: BillingCustomer) => {
                return (
                  <S.ContextButton
                    title={context.name}
                    key={context.id + context.role}
                    type="button"
                    onClick={() => onClickHandler(context.id)}
                    isSearch>
                    <>
                      <ContextSwitcherDropdownLevelIcon
                        role={context.role}
                        type={context.type}
                        mainUserBillingCustomers={mainUserBillingCustomers}
                      />
                      <ContextDetails id={context.id} name={context.name} role={context.role} />
                    </>
                  </S.ContextButton>
                );
              })}
          </S.ContextList>
        )}
      </S.ContextContainer>
    </SearchTermContainer.Provider>
  );
};
