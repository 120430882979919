import React from 'react';
import { HeaderIconProps, HeaderIconIcon } from './headerIcon.types';
import * as S from './headerIcon.styles';

/**
 * This component supports the legacy icons, we can use as long as the header is matching the legacy header
 */
export const HeaderIcon = ({ icon }: HeaderIconProps) => {
  switch (icon) {
    case HeaderIconIcon.Business:
      return (
        <S.HeaderIcon width="1rem" height="1rem" viewBox="0 0 1000 1000">
          <path d="m122 752.6q0 68.8 39.6 107.6 39.6 38.8 104.4 38.8h470.7q64.1 0 103.6-38.8 39.6-38.8 40.3-107.6 0-47.5-7.1-103.6-7.1-56.2-27.7-105.2-20.6-49-56.2-81.5-35.6-32.4-94.9-32.4-7.1 0-22.9 11.9-15.8 11.9-40.3 27.7-24.5 15.8-57.7 26.9-33.2 11.1-72 11.9-39.6 0-72.8-11.9-33.2-11.9-57.7-26.9-24.5-15-40.3-27.7-15.8-12.7-22.1-11.9-58.5 0-95.7 32.4-37.2 32.4-57 81.5-19.8 49-26.9 105.2-7.1 56.2-7.1 103.6zm172.4-503.1q0 45.1 16.6 83.8 16.6 38.8 44.3 68.8 27.7 30.1 65.7 46.7 38 16.6 80.7 16.6 42.7 0 79.9-16.6 37.2-16.6 65.7-46.7 28.5-30.1 44.3-68.8 15.8-38.8 16.6-83.8 0-45.1-16.6-83.8-16.6-38.8-44.3-69.6-27.7-30.8-65.7-46.7-38-15.8-79.9-16.6-41.9-0.8-80.7 16.6-38.8 17.4-65.7 46.7-26.9 29.3-44.3 69.6-17.4 40.3-16.6 83.8z" />
        </S.HeaderIcon>
      );
    case HeaderIconIcon.CaretDown:
      return (
        <S.HeaderIcon width="1rem" height="1rem" viewBox="0 0 1000 1000">
          <path d="m289.2 346.4q0 9.5 8.7 19.8l193.8 193.8q7.9 7.9 19 7.9 11.9 0 19.8-7.9l193.8-193.8q8.7-10.3 8.7-19.8 0-11.1-7.9-19.8-7.9-8.7-20.6-7.9H316.9q-11.1 0-19 7.9-7.9 7.9-8.7 19.8z" />
        </S.HeaderIcon>
      );
    case HeaderIconIcon.Cart:
      return (
        <S.HeaderIcon width="1rem" height="1rem" viewBox="0 0 1000 847">
          <path d="m48 61.1q0 13.9 9.7 23.6 9.7 9.7 23.6 9H187l91.1 426.2q-2.8 6.3-8.3 15.3-5.6 9-10.4 20.2-4.9 11.1-9 20.2-4.2 9-3.5 15.3 0 13.9 9.7 23.6 9.7 9.7 23.6 9.7h529.8q13.2 0 23.6-9.7 10.4-9.7 9.7-23.6 0-13.2-9.7-22.9-9.7-9.7-23.6-9.7H333.7q4.2-7.6 8.3-16 4.2-8.3 4.2-17.4 0-9-2.8-18.1-2.8-9-4.2-18.1l540.9-63.3q12.5-1.4 20.9-11.1 8.3-9.7 8.3-21.6V127.1q0-13.2-9.7-22.9-9.7-9.7-22.9-10.4h-622.2q-2.1-9-3.5-20.2-1.4-11.1-5.6-21.6-4.2-10.4-11.1-17.4-7-7-20.9-7H81.3q-13.9 0-23.6 10.4-9.7 10.4-9.7 22.9zM246.8 690.3q0 27.8 19.5 47.3 19.5 19.5 47.3 19.5 27.8 0 46.6-19.5 18.8-19.5 19.5-47.3 0.7-27.8-19.5-46.6-20.2-18.8-46.6-19.5-26.4-0.7-47.3 19.5-20.9 20.2-19.5 46.6zm463.7 0q0 27.8 19.5 47.3 19.5 19.5 47.3 19.5 27.8 0 46.6-19.5 18.8-19.5 19.5-47.3 0.7-27.8-19.5-46.6-20.2-18.8-46.6-19.5-26.4-0.7-47.3 19.5-20.9 20.2-19.5 46.6z" />
        </S.HeaderIcon>
      );
  }
};
