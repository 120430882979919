import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import nl from 'i18n/nl.json';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import App from './App';

if (process.env.isProduction) {
  const history = createBrowserHistory();

  Sentry.init({
    dsn: 'https://e0484133ca8546a495037adf44bf991b@o575421.ingest.sentry.io/5783924',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.1,
    environment: process.env.PIPELINE,
    release: `mvz-react@${process.env.npm_package_version}`,
  });
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      nl: {
        translation: nl,
      },
    },
    lng: 'nl',
    fallbackLng: 'nl',

    interpolation: {
      escapeValue: true,
    },
  });

ReactDOM.render(<App />, document.getElementById('app'));
