import React, { useContext, useState } from 'react';
import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { UserContext } from 'context/user/user.context';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { getLegacyUrlBase, redirectToLegacy, redirectToMyConsumer } from 'utils/location';
import { useTranslations } from 'components/Intl/useTranslations';
import * as S from './userInfo.styles';
import { HeaderIcon } from '../HeaderIcon/HeaderIcon';
import { HeaderIconIcon } from '../HeaderIcon/headerIcon.types';

export const UserInfo = () => {
  const {
    state: {
      user,
      enterprise: { activeContext },
    },
  } = useContext(UserContext);
  const [isDropdownHidden, setIsDropdownHidden] = useState(true);
  const toggleDropdown = () => setIsDropdownHidden(!isDropdownHidden);
  const { translate } = useTranslations('component.header.userInfo');

  const navigateToDashboard = () => {
    if (activeContext) {
      redirectToLegacy(`${getLegacyUrlBase(activeContext)}/overzicht`);

      return;
    }

    // Probably not logged in? Trigger the login flow
    redirectToMyConsumer();
  };

  const navigateToAccount = () => {
    // Needs to be a full page reload
    window.location.assign(`/account/overzicht`);
  };

  const logout = () => {
    // Needs to be a full page reload
    window.location.assign(`/account/uitloggen`);
  };

  return (
    <Dropdown>
      <S.Col col="auto" onClick={toggleDropdown}>
        <HeaderIcon icon={HeaderIconIcon.Business} />
        <S.Name>{user?.user_name}</S.Name>
        <S.MenuIcon isRotated={!isDropdownHidden}>
          <HeaderIcon icon={HeaderIconIcon.CaretDown} />
        </S.MenuIcon>
      </S.Col>
      <S.DropdownMenu hidden={isDropdownHidden} center toggle={toggleDropdown}>
        <S.ButtonWrapper>
          <div>
            <S.DropdownButton variant={ButtonVariant.DARK} isOutlined onClick={navigateToDashboard}>
              {translate('navigateToDashboard')}
            </S.DropdownButton>
          </div>
          <div>
            <S.DropdownButton variant={ButtonVariant.LINK} onClick={navigateToAccount}>
              {translate('navigateToAccount')}
            </S.DropdownButton>
          </div>
          <div>
            <S.DropdownButton variant={ButtonVariant.DARK} onClick={logout}>
              {translate('logout')}
            </S.DropdownButton>
          </div>
        </S.ButtonWrapper>
      </S.DropdownMenu>
    </Dropdown>
  );
};
