import React from 'react';
import styled, { Breakpoint, Color, css, Spacing } from '@vodafoneziggo/sandwich/theme';
import { LinkProps, NavLink } from 'react-router-dom';
import { down } from 'styles/utils/mediaqueries';
import { MainMenuLinkProps } from './mainMenu.types';

export const Wrapper = styled.div`
  height: 8rem;
  background-color: ${(props) => props.theme.colors.primary};
`;

export const Link = styled(({ isActive, hasSubMenu, ...rest }: MainMenuLinkProps & LinkProps) => <NavLink {...rest} />)`
  padding: ${Spacing.XS};
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.1875rem;
  border-radius: 6px;
  position: relative;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${Color.DARK_RED};

      &::before {
        border-bottom: 0.64rem solid ${Color.GRAY_700};
        border-left: 0.64rem solid transparent;
        border-right: 0.64rem solid transparent;
        border-top: none;
        content: '';
        display: block;
        width: 0.64rem;
        position: absolute;
        top: 4.2rem;
        left: calc(50% - 0.64rem);
      }
    `};

  ${(props) =>
    !props.hasSubMenu &&
    css`
      &::before {
        border-bottom-color: ${Color.WHITE};
      }
    `};

  ${down(Breakpoint.MD)`
    font-size: 0.9375rem;
    
    &::before {
      top: 4.19rem;
    }
  `}
`;
