// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';
import { Exception } from '../graphqlContext.types';

/**
 * This link will catch errors and update the exception state
 */
export const errorLink = (setException: (value: Exception | undefined) => void) =>
  onError(({ graphQLErrors, operation }) => {
    if (!graphQLErrors) {
      return;
    }

    if (operation.operationName.endsWith('OrFailSilent')) {
      return;
    }

    graphQLErrors.forEach((err) => {
      const { message, locations, path } = err;

      // eslint-disable-next-line no-console
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

      // If detailed exception is not available, trigger generic exception without info
      const graphQLException: Exception = err.extensions?.exception || {};

      setException(graphQLException);
    });
  });
