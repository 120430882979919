import styled, { Spacing } from '@vodafoneziggo/sandwich/theme';

export const Wrapper = styled.div`
  padding-bottom: ${Spacing.XS};
  padding-left: ${Spacing.XS};
  padding-right: ${Spacing.XS};
  background: ${(props) => props.theme.colors.redD10};
  color: ${(props) => props.theme.colors.white};
  font-size: 0.875rem;
`;
