import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { useTranslations } from 'components/Intl/useTranslations';

/**
 * @param initialState string Is the page translation key without page. prefix
 */
const usePageTranslations = (initialState: string) => {
  const [pageKey, setPageKey] = useState(initialState);
  const translations = useTranslations(`page.${pageKey}`);

  useEffect(() => {
    document.title = translations.translate('metaTitle');
  }, [translations]);

  return {
    ...translations,
    setPageKey,
  };
};

export const PageTranslationsContainer = createContainer(usePageTranslations);
