import React from 'react';
import styled, { Breakpoint, Color, css, Spacing } from '@vodafoneziggo/sandwich/theme';
import { LinkProps, NavLink } from 'react-router-dom';
import { down } from 'styles/utils/mediaqueries';
import { SubmenuLinkProps } from './submenu.types';

export const Wrapper = styled.div`
  height: 4.375rem;
  display: flex;
  align-items: center;
`;

export const Link = styled(({ isActive, ...rest }: SubmenuLinkProps & LinkProps) => <NavLink {...rest} />)`
  padding: ${Spacing.XS};
  margin: 0 ${Spacing.XS};
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.0625rem;
  border-radius: 6px;
  border: 1px solid ${Color.GRAY_600};

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${Color.GRAY_600};
    `};

  ${down(Breakpoint.MD)`
    font-size: 0.875rem;
    margin: 0 ${Spacing.XS};
  `}
`;
