import React, { useState, useEffect } from 'react';
import {
  AuthProviderContainer as SandwichAuthProvider,
  OidcConfig,
  OidcUtil,
} from '@vodafoneziggo/sandwich/generic/auth';
import API from 'utils/api';
import { GetOidcConfig } from 'config/app.config';
import useInterval from 'hooks/useInterval';

export const AuthProvider: React.FC = ({ children }) => {
  const [oidcConfig, setOidcConfig] = useState<OidcConfig>();

  useEffect(() => {
    GetOidcConfig().then((config) => setOidcConfig(config));
  }, []);

  useInterval(() => {
    // Every minute, we check if the token is about to expire (within the next 5 minutes), and refresh if needed
    OidcUtil.attemptTokenRefresh();
  }, 60 * 1000);

  return (
    <SandwichAuthProvider
      oidcConfig={oidcConfig}
      onAuthTokenChange={(token) => {
        API.setHeader('Authorization', token || '');
      }}>
      {children}
    </SandwichAuthProvider>
  );
};

AuthProvider.displayName = 'AuthProvider';
