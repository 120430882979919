import styled, { Breakpoint } from '@vodafoneziggo/sandwich/theme';
import { down } from 'styles/utils/mediaqueries';

export const Wrapper = styled.div`
  width: 10rem;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  ${down(Breakpoint.MD)`
    display: none;
  `}
`;

export const ContextName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 14.375rem;
  width: auto;
`;

export const ContextEntity = styled.div`
  font-size: 0.875rem;
`;
