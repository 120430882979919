import api from 'utils/api';
import { ApiEndpoints } from 'utils/api/endpoints';
import { getAuthenticatedUserArguments, authenticatedUserApiParams } from './userContext.types';

export const getAuthenticatedUser = ({ accountId, billingCustomerId }: getAuthenticatedUserArguments = {}) => {
  const params: authenticatedUserApiParams = {};

  if (accountId) params.account_id = accountId;
  if (billingCustomerId) params.billing_customer_id = billingCustomerId;

  return api.get(ApiEndpoints.ACCOUNT_AUTHENTICATED_USER, undefined, {
    params,
    getRawData: false,
  });
};
