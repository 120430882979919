import { createGlobalStyle, loadFonts, Breakpoint, FontLevels, Spacing } from '@vodafoneziggo/sandwich/theme';
import React from 'react';
import primary from '@vodafoneziggo/sandwich/fonts/vodafone/vodafonerg-regular.woff';
import primaryLight from '@vodafoneziggo/sandwich/fonts/vodafone/vodafonelt-regular.woff';
import primaryBold from '@vodafoneziggo/sandwich/fonts/vodafone/vodafonerg-bold.woff';
import fallback from '@vodafoneziggo/sandwich/fonts/helvetica-neue/HelveticaNeue.woff';
import { useTheme } from '@vodafoneziggo/sandwich';
import { up } from './utils/mediaqueries';

/**
 * These global styles are absolutely necessary to live on this level.
 * Prefer not to use global styles for specific elements. Create your own custom component for that.
 */
const Global = createGlobalStyle`
  /* Use the correct breakpoint for the WS10 theme */
  html body {
    ${FontLevels.LEVEL_6};
    
    ${up(Breakpoint.L)`
      ${FontLevels.BODY};
    `}
  }

  /** 
   * Vertical aligning is used on all icons we have. 
   * Most of the time those need to be aligned in the middle of some text next to it.
   */
  svg {
    vertical-align: middle;
  }

  hr {
    border: none;
    border-top: 1px solid ${(props) => props.theme.colors.grayL30};
  }

  /** 
   * Reset all the button styles before we style any button on top of that. 
   * We do not use the browser's default styling anywhere.
   */
  button {
    margin: 0;
    padding: 0;
    border: 0;
    color: inherit;
    font: inherit;
    text-transform: none;
    appearance: none;
    background: none;
    outline: none;
  }

  a {
    text-decoration: none;
  }

  h1 {
    ${FontLevels.LEVEL_2};
    font-weight: normal;
    margin: ${Spacing.M} 0;

    ${up(Breakpoint.SM)`
      ${FontLevels.LEVEL_1};
    `};
  }

  h2 { 
    ${FontLevels.LEVEL_5};
    font-weight: bold;
    margin: 0;
    margin-bottom: ${Spacing.S};

    ${up(Breakpoint.SM)`
      ${FontLevels.LEVEL_4};
    `};
  }

  h3 {
    ${FontLevels.LEVEL_6};
    font-weight: bold;
    margin: 0;
    margin-bottom: ${Spacing.XS};

    ${up(Breakpoint.SM)`
      ${FontLevels.LEVEL_5};
    `};
  }

  h4,
  h5,
  h6 {
    ${FontLevels.LEVEL_6};
    
    ${up(Breakpoint.L)`
      ${FontLevels.BODY};
    `}
    font-weight: bold;  
  }

  p {
    ${FontLevels.LEVEL_6};
    
    ${up(Breakpoint.L)`
      ${FontLevels.BODY};
    `}
  }
`;

/**
 * Build the global font-face declaration for the Vodafone fonts.
 */
const Fonts = loadFonts(primary, primaryLight, primaryBold, fallback);

/**
 * Global styles receive current brand as prop, to force global styling elements to rerender
 * when brand changes.
 */
export const GlobalAppStyle = () => {
  const theme = useTheme();

  return (
    <React.Fragment key={theme.name}>
      <Global />
      <Fonts />
    </React.Fragment>
  );
};
