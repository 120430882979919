import styled from '@vodafoneziggo/sandwich/theme';
import { DropdownMenu as StyledDropdownMenu } from 'styled-dropdown-component';
import { DropdownMenuProps } from './dropdownMenu.types';

const arrowRightPosition = (props: DropdownMenuProps) => {
  if (props.right) return '1rem';
  if (props.center) return 'calc(50% - 0.64rem)';

  return 'calc(100% - 1.64rem)';
};

export const DropdownMenu = styled(StyledDropdownMenu)<DropdownMenuProps>`
  border: none;
  border-radius: 0;
  box-shadow: ${(props) => props.theme.shadows.base};
  padding: 0;

  /* arrow */
  &::after {
    border-bottom: 0.64rem solid white;
    border-left: 0.64rem solid transparent;
    border-right: 0.64rem solid transparent;
    border-top: none;
    content: '';
    display: block;
    position: absolute;
    right: ${arrowRightPosition};
    top: -0.64rem;
  }

  /* shadow for the arrow */
  &::before {
    border-bottom: 0.64rem solid rgba(0, 0, 0, 0.1);
    border-left: 0.64rem solid transparent;
    border-right: 0.64rem solid transparent;
    border-top: none;
    content: '';
    display: block;
    position: absolute;
    right: ${arrowRightPosition};
    top: -0.68rem;
  }
`;
