import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { useTranslations } from 'components/Intl/useTranslations';
import * as S from './footer.styles';

export const Footer = () => {
  const { translate } = useTranslations('component.footer');

  return (
    <S.Footer>
      <Container>
        <Row>
          <Col md={3}>
            <S.Heading>{translate('header.aboutVodafone')}</S.Heading>
            <S.LinkWrapper>
              <a href="/over-vodafone-ziggo/">{translate('anchor.VZInHolland')}</a>
            </S.LinkWrapper>
            <S.LinkWrapper>
              <a href="http://www.vodafone.com" target="_blank" rel="noreferrer">
                {translate('anchor.vodafoneGroup')}
              </a>
            </S.LinkWrapper>
          </Col>
          <Col md={4}>
            <S.Heading>{translate('header.aboutThisWebsite')}</S.Heading>
            <S.LinkWrapper>
              <a href="/support/over-deze-website/privacy-en-disclaimer.shtml">{translate('anchor.privacy')}</a>
            </S.LinkWrapper>
            <S.LinkWrapper>
              <a href="/over-deze-website/cookies.shtml">{translate('anchor.cookies')}</a>
            </S.LinkWrapper>
            <S.LinkWrapper>
              <a href="/support/abonnement-en-rekening/tarieven-en-voorwaarden.shtml#abonnementen">
                {translate('anchor.termsAndConditions')}
              </a>
            </S.LinkWrapper>
          </Col>
          <Col md={5}>
            <S.SocialWrapper>
              <S.Heading>{translate('header.followUs')}</S.Heading>
              <S.SocialIconWrapper>
                <a href="http://forum.vodafone.nl" rel="noreferrer" target="_blank">
                  {translate('social.vodafoneForum')}
                </a>
              </S.SocialIconWrapper>
              <S.SocialIconWrapper>
                <a href="https://www.facebook.com/VodafoneNL" rel="noreferrer" target="_blank">
                  {translate('social.facebook')}
                </a>
              </S.SocialIconWrapper>
              <S.SocialIconWrapper>
                <a href="https://twitter.com/vodafoneNL" rel="noreferrer" target="_blank">
                  {translate('social.twitter')}
                </a>
              </S.SocialIconWrapper>
              <S.SocialIconWrapper>
                <a href="https://www.linkedin.com/company/vodafone" rel="noreferrer" target="_blank">
                  {translate('social.linkedIn')}
                </a>
              </S.SocialIconWrapper>
              <S.SocialIconWrapper>
                <a href="https://www.youtube.com/user/vodafonenl" rel="noreferrer" target="_blank">
                  {translate('social.youtube')}
                </a>
              </S.SocialIconWrapper>
            </S.SocialWrapper>
          </Col>
        </Row>
      </Container>
    </S.Footer>
  );
};
