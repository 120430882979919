import { useContext, useMemo } from 'react';
import { UserContext } from 'context/user/user.context';
import { MAIN_USER_ROLES } from 'config/permissions/main-user-roles.types';

/**
 * Provides the user context currently stored.
 */
export const useUser = () => {
  const { state: userState } = useContext(UserContext);

  return useMemo(
    () => ({
      userState,
      isIwr: userState?.enterprise?.activeContext?.access_settings?.is_iwr,
      isGtf: userState?.enterprise?.activeContext?.access_settings?.is_gtf,
      customerLevelProducts: userState?.user?.enterprise?.customer_level_products || [],
      accountHasServiceNavigator: userState?.enterprise?.activeContext?.main_user_roles.includes(
        MAIN_USER_ROLES.FIXED_WEB_PORTAL
      ),
    }),
    [userState]
  );
};
