import styled, { Breakpoint, FontLevels, Spacing } from '@vodafoneziggo/sandwich/theme';
import { up } from 'styles/utils/mediaqueries';

export const Footer = styled.div`
  background: linear-gradient(to bottom, #222 0%, #333 100%);
  padding-top: ${Spacing.XL};
  padding-bottom: ${Spacing.XXL};
  line-height: 1.15;
`;

export const Heading = styled.h3`
  color: ${(props) => props.theme.colors.white};
  ${FontLevels.LEVEL_6}
`;

export const LinkWrapper = styled.div`
  a {
    color: ${(props) => props.theme.colors.grayL20};
    font-size: 0.85rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SocialWrapper = styled.div`
  ${up(Breakpoint.MD)`
    float: right;
  `}
`;

export const SocialIconWrapper = styled.span`
  a {
    background-image: url('/zakelijk/my/v2/static/images/footer-icon.png');
    background-repeat: no-repeat;
    background-size: 13.25rem 9.938rem;
    display: inline-block;
    height: 2.688rem;
    overflow: hidden;
    margin-right: ${Spacing.XS};
    text-indent: -9999rem;
    width: 2.688rem;
  }

  &:last-child {
    a {
      margin-right: 0;
    }
  }

  &:nth-of-type(1) {
    a {
      background-position: -58px -111px;

      &:hover {
        background-position: -5px -111px;
      }
    }
  }

  &:nth-of-type(2) {
    a {
      background-position: -164px -5px;

      &:hover {
        background-position: -111px -111px;
      }
    }
  }

  &:nth-of-type(3) {
    a {
      background-position: -58px -58px;

      &:hover {
        background-position: -5px -58px;
      }
    }
  }

  &:nth-of-type(4) {
    a {
      background-position: -111px -58px;

      &:hover {
        background-position: -111px -5px;
      }
    }
  }

  &:nth-of-type(5) {
    a {
      background-position: -58px -5px;

      &:hover {
        background-position: -5px -5px;
      }
    }
  }
`;
