/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { UserContext } from 'context/user/user.context';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackRouteChange } from 'utils/ddm';

export const Tagging = () => {
  const { pathname } = useLocation();
  const {
    state: {
      user,
      enterprise: { activeContext },
    },
  } = useContext(UserContext);

  useEffect(() => {
    if (!user?.contact) {
      return;
    }

    if (!activeContext) {
      return;
    }

    trackRouteChange(pathname, user, activeContext);
  }, [pathname, user, activeContext]);

  return null;
};
