import { useEffect, useState, useContext } from 'react';
import { LocalStorage } from 'utils/storage';
import { getLegacyUrlBase } from 'utils/location';
import { UserContext } from 'context/user/user.context';
import { IMainMenuItem } from './useMenu.types';

/**
 * This hook will read the activeMenu from localStorage (set by the legacy portal). It will also
 * attempt to refresh the localStorage value, when it is not found, by using a hidden iframe.
 */
export const useLocalStorageMenu = () => {
  const [menu, setMenu] = useState<IMainMenuItem[]>();
  const [persistedMenu, setPersistedMenu] = useState<any>(LocalStorage.get('activeMenu'));
  const [storageFallbackUrl, setStorageFallbackUrl] = useState('');

  const {
    state: {
      enterprise: { activeContext },
    },
  } = useContext(UserContext);

  // Parse the persisted menu from localStorage
  useEffect(() => {
    if (!activeContext?.id) return;

    if (!persistedMenu || !persistedMenu[activeContext.type] || !persistedMenu[activeContext.type][activeContext.id]) {
      // The menu state was not found in localStorage, activate the fallback
      setStorageFallbackUrl(`${getLegacyUrlBase(activeContext)}/overzicht`);
      setMenu([]);

      return;
    }

    // Stop fallback if necessarry
    setStorageFallbackUrl('');
    setMenu(persistedMenu[activeContext.type][activeContext.id]);
  }, [activeContext, persistedMenu]);

  // When the storageFallback is set, use an iframe to load the url,
  // and activate an interval to fetch the updated session
  useEffect(() => {
    if (!storageFallbackUrl) return;

    // Interval to refresh the new localStorage value
    const interval = setInterval(() => {
      setPersistedMenu(LocalStorage.get('activeMenu'));
    }, 200);

    // Create and append a hidden iframe
    const iFrame: HTMLIFrameElement = document.createElement('iframe');

    iFrame.id = 'silentRefreshActiveMenuIframe';
    iFrame.src = `/zakelijk/my${storageFallbackUrl}`;
    // Don't use display none, so JS will activate
    iFrame.style.width = '0';
    iFrame.style.height = '0';
    iFrame.style.opacity = '0';

    window.document.body.appendChild(iFrame);

    return () => {
      // Stop refreshing localStorage value
      clearInterval(interval);

      // Remove the iframe
      setTimeout(() => window.document.body.removeChild(iFrame), 0);
    };
  }, [storageFallbackUrl]);

  return menu;
};
