import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useSearchTerm = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isPristine, setIsPristine] = useState(true);

  return {
    isPristine,
    searchTerm,
    setSearchTerm: (term: string) => {
      setSearchTerm(term);
      setIsPristine(false);
    },
  };
};

export const SearchTermContainer = createContainer(useSearchTerm);
