import React, { useState } from 'react';
import { Icon, MY_VFLOGO, CORPORATE_MENU } from '@vodafoneziggo/sandwich';
import { useMenu } from 'components/Header/hooks/useMenu';
import { useSubmenu } from 'components/Header/hooks/useSubmenu';
import * as S from './mobileMenu.styles';

export const MobileMenu = () => {
  const { menuItems, currentMenuItem } = useMenu();
  const { submenuItems, currentSubmenuItem } = useSubmenu();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Icon icon={MY_VFLOGO} height="3rem" width="3rem" />
      <S.Button onClick={() => setIsOpen(!isOpen)}>
        <Icon icon={CORPORATE_MENU} height="2rem" width="2rem" />
      </S.Button>
      {isOpen && <S.CloseOverlay onClick={() => setIsOpen(false)} />}
      {isOpen && (
        <S.Menu>
          {menuItems.map((menuItem) => (
            <React.Fragment key={menuItem.url}>
              <S.Link isActive={currentMenuItem === menuItem} to={menuItem.url}>
                {menuItem.name}
              </S.Link>
              {currentMenuItem === menuItem &&
                submenuItems.map((submenuItem) => (
                  <S.Link
                    isActive={currentSubmenuItem === submenuItem}
                    isSubmenuItem
                    to={submenuItem.url}
                    key={submenuItem.url}>
                    {submenuItem.name}
                  </S.Link>
                ))}
            </React.Fragment>
          ))}
        </S.Menu>
      )}
    </>
  );
};
