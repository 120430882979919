import styled, { Breakpoint, Spacing } from '@vodafoneziggo/sandwich/theme';
import { down } from 'styles/utils/mediaqueries';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${(props) => props.theme.colors.white};
  padding-bottom: ${Spacing.M};

  h1 {
    color: ${(props) => props.theme.colors.primary};

    ${down(Breakpoint.SM)`
      font-size: 1.5rem;
    `}

    ${down(Breakpoint.XS)`
      font-weight: bold;
      font-size: 1rem;
      margin: ${Spacing.M} 0;
    `}
  }
`;
