import { CSSObject, SimpleInterpolation } from 'styled-components';
import { css } from '@vodafoneziggo/sandwich';
import { Breakpoint, breakpointValues } from './get-grid-theme.types';

export type MediaQueryVariants = 'up' | 'down' | 'only' | 'between';

/* Media query helpers */

/**
 * Checks if there is a breakpoint that comes after the 'breakpoint' argument.
 * @param breakpoint Breakpoint name
 */
const getNextBreakpoint = (breakpoint: Breakpoint) => {
  const breakpoints = Object.values(breakpointValues).sort((a, b) => a - b);
  const index = breakpoints.indexOf(breakpoint);

  return breakpoints[index + 1] as Breakpoint | undefined;
};

/* Media query methods */

/**
 * From this breakpoint (including) and up.
 * @param breakpoint
 */
export const up =
  (breakpoint: Breakpoint) =>
  (first: CSSObject | TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => {
    return css`
      @media only screen and (min-width: ${breakpoint}px) {
        ${css(first, ...interpolations)}
      }
    `;
  };

/**
 * Until this breakpoint (including).
 * @param breakpoint
 */
export const down =
  (breakpoint: Breakpoint) =>
  (first: CSSObject | TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => {
    const nextBreakpoint = getNextBreakpoint(breakpoint);

    if (!nextBreakpoint) {
      // always
      return css(first, ...interpolations);
    }

    return css`
      @media only screen and (max-width: ${`${nextBreakpoint - 1}px`}) {
        ${css(first, ...interpolations)}
      }
    `;
  };

/**
 * From breakpoint 'min' until breakpoint 'max'.
 * @param min
 * @param max
 */
export const between =
  (min: Breakpoint, max: Breakpoint) =>
  (first: CSSObject | TemplateStringsArray, ...interpolations: SimpleInterpolation[]) => {
    return css`
      @media only screen and (min-width: ${min}px) and (max-width: ${max - 1}px) {
        ${css(first, ...interpolations)}
      }
    `;
  };

/**
 * Only a specific range. Eg only('md') will result in between('md', 'lg').
 * @param breakpoint
 */
export const only = (breakpoint: Breakpoint) => {
  const nextBreakpoint = getNextBreakpoint(breakpoint);

  return nextBreakpoint ? between(breakpoint, nextBreakpoint) : up(breakpoint);
};

export default {
  up,
  down,
  between,
  only,
};
