import { createBrowserHistory } from 'history';

/**
 * Send page visit tagging to DDM, and update live person with the new page url
 */
const tagPageVisit = () => {
  try {
    // to be implementes
  } catch (e) {
    // Triggers and sent and forgotten, errors will be discarded
  }
};

/**
 * Create the actual browser history
 */
export const history = createBrowserHistory({
  basename: '/zakelijk/my',
});

/**
 * Global listeners which should fire on each page visit.
 */
history.listen(tagPageVisit);

/**
 * Also send page visit to DDM on page load
 */
tagPageVisit();
