import React from 'react';
import { useMenu } from 'components/Header/hooks/useMenu';
import * as S from './MainMenu.styles';

export const MainMenu = () => {
  const { menuItems, currentMenuItem } = useMenu();

  return (
    <>
      {menuItems.map((menuItem) => {
        return (
          <S.Link
            isActive={currentMenuItem === menuItem}
            hasSubMenu={currentMenuItem === menuItem && currentMenuItem.submenu?.length > 0}
            to={menuItem.url}
            key={menuItem.url}>
            {menuItem.name}
          </S.Link>
        );
      })}
    </>
  );
};
