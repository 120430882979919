import React, { ChangeEvent, FormEvent } from 'react';
import { CORPORATE_SEARCH, Icon, Input } from '@vodafoneziggo/sandwich';
import { useContainer } from 'unstated-next';
import { SearchTermContainer } from 'containers/Other/SearchTerm.container';
import * as S from './searchBar.styles';

export interface OnSearchSubmitProps {
  searchTerm: string;
}

interface SearchBarProps {
  label: string;
  onSearchSubmit: ({ searchTerm }: OnSearchSubmitProps) => void;
  testId?: string;
}

export const SearchBar = ({ label, onSearchSubmit, testId }: SearchBarProps) => {
  const { searchTerm, setSearchTerm } = useContainer(SearchTermContainer);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSearchSubmit({ searchTerm });
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <S.Form onSubmit={onSubmit} data-testid={`search${testId ? `-${testId}` : ''}`}>
      <Input value={searchTerm} onChange={onChange} placeholder={label} type="text" testId="searchTermInput" />
      <S.Submit type="submit">
        <Icon icon={CORPORATE_SEARCH} height="1.5rem" width="1.5rem" />
      </S.Submit>
    </S.Form>
  );
};
