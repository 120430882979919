// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';
import { Exception } from '../graphqlContext.types';

/**
 * This method will return an array of links, which will all retry the request in case of a network error on a query.
 * The resulting array should be spread onto the ApolloLink array for ApolloClient link property
 *
 * @param setException callback to update the exception in case all retries failed
 * @param maxRetries number of retries (after the first failing request)
 */
export const networkErrorLinks = (setException: (value: Exception | undefined) => void, maxRetries = 2) => {
  // We are not using @apollo/client/link/retry because we don't want the requests that fail to still trigger an error.
  // Only when all retries fail, we want to trigger the upstream links which will show the error to the user
  const links = [];
  const networkErrorLink = onError(({ networkError, operation, forward }) => {
    const definition = operation.query.definitions[0] as any;

    if (!networkError) {
      // No network error error
      return;
    }

    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);

    if (definition?.operation !== 'query') {
      // We will only retry in case of a query
      // Trigger generic exception without info
      setException({});

      return;
    }

    const oldHeaders = operation.getContext().headers;
    let retryCount = 0;

    if (oldHeaders) {
      retryCount = oldHeaders['x-retry-count'];
    }

    if (retryCount >= maxRetries) {
      // Trigger generic exception without info
      setException({});

      return;
    }

    // Do a retry
    operation.setContext({
      headers: {
        ...(oldHeaders || {}),
        'x-retry-count': retryCount + 1,
      },
    });

    return forward(operation);
  });

  // Let add the link as many times as needed
  for (let i = 0; i < maxRetries; i += 1) {
    links.push(networkErrorLink);
  }

  return links;
};
