import { Features, FrontendConfigsContainer } from 'containers/Other/FrontendConfigs.container';
import { useContainer } from 'unstated-next';

/**
 * Returns a feature flag value from fe-configs, if the feature flag does not exist, false will be returned
 *
 * @param feature Feature name
 * @param defaultValue The value to return when fe-configs is not resolved yet
 * @returns boolean | undefined
 */
export const useFeatureFlag = (feature: keyof Features, defaultValue?: boolean) => {
  const { features } = useContainer(FrontendConfigsContainer);

  if (!features) return defaultValue;

  // If the flag does not exists, treat it as disabled
  return features[feature] || false;
};
