import styled, { Breakpoint, css, Spacing } from '@vodafoneziggo/sandwich/theme';
import { Icon } from '@vodafoneziggo/sandwich';
import { down, up } from 'styles/utils/mediaqueries';

import { MenuIconProps } from './contextSwitcher.types';

export const ContextSwitcher = styled.div`
  display: block;
  position: relative;
  width: 18rem;
  line-height: 1.25;
  z-index: 2;

  ${down(Breakpoint.MD)`
    width: 5.5rem;
  `}

  ${down(Breakpoint.SM)`
    width: auto;
    margin-left: 0.625rem;
  `}
`;

export const Button = styled.div`
  height: 3.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.redD10};
  cursor: pointer;
  padding: ${Spacing.M};

  ${down(Breakpoint.SM)`
    display: none;
  `}
`;

export const TopRowButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding-right: ${Spacing.M};

  ${up(Breakpoint.MD)`
    display: none;
  `}
`;

export const TopRowName = styled.div`
  color: ${(props) => props.theme.colors.white};
  max-width: 10.5rem;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: ${Spacing.XS};
`;

export const MenuIconWrapper = styled.div<MenuIconProps>`
  align-items: center;
  border-top-right-radius: 4px;
  display: flex;
  height: 3.65rem;
  justify-content: center;
  position: relative;
  right: -1rem;
  top: -0.05rem;
  width: 2.75rem;

  ${(props) =>
    props.isRotated &&
    css`
      background-color: ${({ theme }) => theme.colors.redD10};
      z-index: 2;
    `}
`;

export const MenuIcon = styled(Icon)<MenuIconProps>`
  transition: transform ${(props) => props.theme.transitions.default};

  ${(props) =>
    props.isRotated &&
    css`
      transform: scale(1, -1);
    `}
`;

export const TopRowMenuIcon = styled.div<MenuIconProps>`
  display: flex;
  align-items: center;
  transition: transform ${(props) => props.theme.transitions.default};

  ${(props) =>
    props.isRotated &&
    css`
      transform: scale(1, -1);
    `}
`;

export const ContextContainer = styled.div`
  max-height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const RegularContextContainer = styled.div`
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ContextButton = styled.button<{ isSearch?: boolean }>`
  align-items: center;
  background: ${(props) => props.theme.colors.redD20};
  border: none;
  color: #fff;
  display: flex;
  text-align: left;
  transition: background-color 0.2s linear;
  width: 100%;
  padding: ${Spacing.XS};

  &:last-child {
    border-radius: ${(props) => (props.isSearch ? '0' : '0 0 4px 4px')};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grayD40};
    cursor: pointer;
  }
`;

export const ConnectionIconWrapper = styled.span`
  color: ${(props) => props.theme.colors.redD30};
  transform: translateY(-0.7rem);
  margin-left: 1rem;
`;

export const ContextList = styled.div`
  background: ${(props) => props.theme.colors.redD10};
  padding-right: ${Spacing.XS};
  padding-bottom: ${Spacing.XS};
  padding-left: ${Spacing.XS};

  button {
    background: ${(props) => props.theme.colors.redD20};
  }
`;
