import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EnterpriseContextType } from '@vodafoneziggo/sandwich/models/user.model';

const contextTypePath: { [key: string]: EnterpriseContextType } = {
  account: EnterpriseContextType.ACCOUNT,
  organisatie: EnterpriseContextType.CUSTOMER,
};

/**
 * Extract the context type and id from the pathname.
 *
 * @param pathname Location pathname to match
 */
const matchLocationContextInfo = (pathname: string) => {
  const pathMatch = /\/(account|organisatie)\/(\d+)\//.exec(pathname);

  if (!pathMatch) {
    return { contextType: undefined, contextId: undefined };
  }

  return { contextType: contextTypePath[pathMatch[1]], contextId: pathMatch[2] };
};

/**
 * Extract the context type and id from the location, when available.
 * Also subscribes to changes on the location
 */
export const useLocationContextInfo = () => {
  const location = useLocation();
  const [locationContextInfo, setLocationContextInfo] = useState(matchLocationContextInfo(location.pathname));

  // Listen for path changes
  useEffect(() => {
    setLocationContextInfo(matchLocationContextInfo(location.pathname));
  }, [location.pathname]);

  return locationContextInfo;
};
