import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { IEnterpriseContext } from '@vodafoneziggo/sandwich/models';
import { EnterpriseContextType } from '@vodafoneziggo/sandwich/models/user.model';
import { useContainer } from 'unstated-next';
import { UserContext } from 'context/user/user.context';
import { getUrlBase } from 'utils/location';
import { ContextSwitcherContainer } from 'components/Header/contextSwitcher.container';
import { useTranslations } from 'components/Intl/useTranslations';
import { useUser } from 'hooks/useUser';

import { ContextDetails } from '../ContextDetails/ContextDetails';
import { ContextSwitcherIcon } from '../ContextSwitcherIcon/ContextSwitcherIcon';
import { ContextSwitcherIconIcon } from '../ContextSwitcherIcon/contextSwitcherIcon.types';
import { BillingCustomersList } from '../ContextSwitcher/BillingCustomersList/BillingCustomersList';
import { RegularContextList } from '../ContextSwitcher/RegularContextList/RegularContextList';
import { BillingCustomersContainer } from '../ContextSwitcher/BillingCustomersList/billingCustomers.container';

import { ContextSwitcherDropdownProps } from './contextSwitcherDropdown.types';
import * as S from './contextSwitcherDropdown.styles';

export const ContextSwitcherDropdown = ({ searchContainerRef }: ContextSwitcherDropdownProps) => {
  const { translate } = useTranslations('component.contextSwitcher');
  const { setIsVisible } = useContainer(ContextSwitcherContainer);

  const { isIwr } = useUser();

  const {
    state: {
      enterprise: { contexts, choosableContexts, totalBillingCustomers = 1 },
    },
    setActiveContext,
  } = useContext(UserContext);
  const history = useHistory();

  /**
   * Handles switching to a different context.
   *
   * @param context
   */
  const switchContext = (context: IEnterpriseContext) => {
    setActiveContext(context);
    setIsVisible(false);
    history.push(`${getUrlBase(context)}/overzicht`);
  };

  const accountContext = contexts.find((context: IEnterpriseContext) => context.type === EnterpriseContextType.ACCOUNT);

  if (!contexts) {
    return null;
  }

  return (
    <>
      <S.ContextVeil />
      <S.ContextList>
        {accountContext && (
          <S.ContextButton type="button" onClick={() => switchContext(accountContext)}>
            {isIwr ? (
              <ContextSwitcherIcon icon={ContextSwitcherIconIcon.IWR} />
            ) : (
              <ContextSwitcherIcon icon={ContextSwitcherIconIcon.TreeWithMarkedTop} />
            )}
            <ContextDetails id={accountContext.id} name={accountContext.name} role={accountContext.role} />
          </S.ContextButton>
        )}
        {totalBillingCustomers > 10 ? (
          <BillingCustomersContainer.Provider>
            <BillingCustomersList searchContainerRef={searchContainerRef} />
          </BillingCustomersContainer.Provider>
        ) : (
          <RegularContextList contexts={contexts} switchContext={switchContext} />
        )}
        {choosableContexts?.length > 1 && (
          <NavLink to="/maak-een-keuze" style={{ textDecoration: 'none' }}>
            <S.ChooseContext>{translate('chooseContext')}</S.ChooseContext>
          </NavLink>
        )}
      </S.ContextList>
    </>
  );
};
