import { useEffect } from 'react';
import { redirectToLegacy } from 'utils/location';

export const ChooseContext = () => {
  // Since the choosecontext page of legacy should still be used, lets redirect there...
  useEffect(() => {
    redirectToLegacy('/maak-een-keuze');
  }, []);

  return null;
};
