import styled, { Spacing } from '@vodafoneziggo/sandwich/theme';

export const ContextVeil = styled.div`
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
`;

export const ContextList = styled.div`
  position: absolute;
  right: 0;
  width: 21.25rem;
`;

export const ContextButton = styled.button`
  align-items: center;
  background-color: ${(props) => props.theme.colors.redD10};
  border: none;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  text-align: left;
  width: 100%;
  cursor: pointer;
  padding: ${Spacing.XS} ${Spacing.S};

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grayD40};
  }
`;

export const ConnectionIconWrapper = styled.span`
  color: ${(props) => props.theme.colors.redD30};
  transform: translateY(-0.7rem);
`;

export const ChooseContext = styled.div`
  font-size: 1rem;
  background-color: ${(props) => props.theme.colors.redD10};
  border-radius: 0 0 4px 4px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
  padding: ${Spacing.S};

  &:hover {
    text-decoration: underline;
  }
`;
