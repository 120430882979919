import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { wrapWithCancellation } from '@vodafoneziggo/sandwich';
import { getFromFeConfigs } from 'utils/api';

export interface V2Config {
  enabled: string[];
}

export interface Features {
  hardwareOrdering: boolean;
  invoicesPayment: boolean;
  invoicesPaymentMethodCreditCard: boolean;
}

const useFrontendConfigs = () => {
  const [v2Config, setV2Config] = useState<V2Config>();
  const [features, setFeatures] = useState<Features>();

  useEffect(() => {
    const cancellation = wrapWithCancellation();

    getFromFeConfigs('enterprise/v2.json').then(
      cancellation.wrapper(({ data }) => {
        setV2Config(data);
      })
    );

    // Return the cancellation cancel method as cleanup method. This will prevent unwanted
    // side effects when the promise resolves after an unmount
    return cancellation.cancel;
  }, []);

  useEffect(() => {
    const cancellation = wrapWithCancellation();

    getFromFeConfigs('enterprise/features.json').then(
      cancellation.wrapper(({ data }) => {
        setFeatures(data);
      })
    );

    // Return the cancellation cancel method as cleanup method. This will prevent unwanted
    // side effects when the promise resolves after an unmount
    return cancellation.cancel;
  }, []);

  return {
    v2Config,
    features,
  };
};

export const FrontendConfigsContainer = createContainer(useFrontendConfigs);
