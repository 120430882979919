import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useContainer } from 'unstated-next';
import { UserContext } from 'context/user/user.context';
import { getMenuItemUrl, hasMenuItemPermission } from 'utils/location';
import { FrontendConfigsContainer } from 'containers/Other/FrontendConfigs.container';
import { IMainMenuItem } from './useMenu.types';
import { useLocalStorageMenu } from './useLocalStorageMenu';

/**
 * Returns the menu items filtered by permissions
 */
export const useMenu = () => {
  const activeMenu = useLocalStorageMenu();
  const [filteredMenuItems, setFilteredMenuItems] = useState<IMainMenuItem[]>([]);
  const [currentMenuItem, setCurrentMenuItem] = useState<IMainMenuItem>();
  const { v2Config } = useContainer(FrontendConfigsContainer);

  const location = useLocation();
  const {
    state: {
      enterprise: { activeContext },
    },
  } = useContext(UserContext);

  // Filter activeMenu on permissions, also sets the url property
  useEffect(() => {
    // Active menu not determined yet, make sure to empty the state
    if (!activeMenu) return setFilteredMenuItems([]);
    // Active context not determined yet, make sure to empty the state
    if (!activeContext?.id) return setFilteredMenuItems([]);
    // V2 config not loaded yet, make sure to empty the state
    if (!v2Config) return setFilteredMenuItems([]);

    const menuItems = activeMenu.filter((item) => hasMenuItemPermission(activeContext, item));

    setFilteredMenuItems(
      menuItems.map((submenuItem) => ({ ...submenuItem, url: getMenuItemUrl(activeContext, v2Config, submenuItem) }))
    );
  }, [activeMenu, activeContext, v2Config]);

  // Determine current menu item
  useEffect(() => {
    const currentBaseRoute = location.pathname.split('/')[4];
    const routePart = location.pathname.split('/')[5];
    /* In customer context, user-management routes are under `beheer`. The `Beheerders` menu item is present in the main menu as well as in the submenu.
      Whenever user routes to `Beheerders` from the mainmenu or submenu, `Beheerders` in main menu should get activated and not the submenu item
      (at least that's how it happens in legacy so we're mimicking that behaviour here)
      Below line makes sure that happens. */
    const regexLastPart =
      currentBaseRoute === 'beheer' && routePart.includes('gebruikersbeheer') ? `/${routePart}` : '';
    // Why did we remove this `/`?
    // Because `completeUrl` does not have a trailing slash. `completeUrl` is basically the `state_url` from legacy's routes.js
    const baseRouteRegExp = new RegExp(`:context_id/${currentBaseRoute}${regexLastPart}`);

    setCurrentMenuItem(filteredMenuItems.find((item) => baseRouteRegExp.test(item.stateObj.completeUrl)));
  }, [location, filteredMenuItems]);

  return { menuItems: filteredMenuItems, currentMenuItem };
};
