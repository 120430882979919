import React, { useContext } from 'react';
import { IEnterpriseContext } from '@vodafoneziggo/sandwich/models';
import { UserContext } from 'context/user/user.context';

import { EnterpriseContextType } from '@vodafoneziggo/sandwich/models/user.model';
import { ContextSwitcherIcon } from '../../ContextSwitcherIcon/ContextSwitcherIcon';
import { ContextDetails } from '../../ContextDetails/ContextDetails';
import { ContextSwitcherIconIcon } from '../../ContextSwitcherIcon/contextSwitcherIcon.types';
import { ContextSwitcherDropdownLevelIcon } from '../../ContextSwitcherDropdownLevelIcon/ContextSwitcherDropdownLevelIcon';
import * as S from '../contextSwitcher.styles';

import { RegularContextListProps } from './regularContextList.types';

export const RegularContextList = ({ contexts, switchContext }: RegularContextListProps) => {
  const { mainUserBillingCustomers } = useContext(UserContext);

  const hasAccountAccess = Boolean(contexts.find((context) => context.type === EnterpriseContextType.ACCOUNT));

  return (
    <S.RegularContextContainer>
      {contexts
        .filter((context: IEnterpriseContext) => context.type !== EnterpriseContextType.ACCOUNT)
        .sort((context_A: IEnterpriseContext, context_B: IEnterpriseContext) =>
          context_A.name.localeCompare(context_B.name)
        )
        .map((context: IEnterpriseContext) => {
          return (
            <S.ContextButton
              key={context.id + context.role}
              type="button"
              onClick={() => switchContext(context)}
              title={context.name}>
              {hasAccountAccess && (
                <S.ConnectionIconWrapper>
                  <ContextSwitcherIcon icon={ContextSwitcherIconIcon.TreeConnections} />
                </S.ConnectionIconWrapper>
              )}
              <ContextSwitcherDropdownLevelIcon
                role={context.role}
                type={context.type}
                mainUserBillingCustomers={mainUserBillingCustomers}
              />
              <ContextDetails id={context.id} name={context.name} role={context.role} />
            </S.ContextButton>
          );
        })}
    </S.RegularContextContainer>
  );
};
