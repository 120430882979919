export enum CONTEXT_ROLES {
  /**
   * A main user (beheerder) is able to manage settings, subscriptions, invoices and other Vodafone product
   * related matters, depending on his assigned main user roles.
   */
  MAIN_USER = 'MAIN_USER',

  /**
   * An end user (medewerker) is the one using one or more subscriptions. He has few rights for editing his
   * product and is mainly able to view matters related to his subscription like usage, addons and sim information.
   */
  END_USER = 'END_USER',

  /**
   * An online friend doesn’t have any products or roles en has therefore no access to the portal.
   * When logging in, an online friend lands on a special page containing an informative message.
   * An online friend can still change his online account.
   */
  FRIEND = 'FRIEND',
}
