export enum ContextSwitcherIconIcon {
  Building,
  IWR,
  Person,
  TreeConnections,
  TreeWithMarkedBottom,
  TreeWithMarkedTop,
}

export interface ContextSwitcherIconProps {
  icon: ContextSwitcherIconIcon;
}
