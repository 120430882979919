import React from 'react';
import styled, { Color, css, Spacing } from '@vodafoneziggo/sandwich/theme';
import { LinkProps, NavLink } from 'react-router-dom';
import { MobileMenuLinkProps } from './mobileMenu.types';

export const Button = styled.div`
  background-color: ${(props) => props.theme.colors.redD10};
  border-radius: 3px;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CloseOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999998;
`;

export const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 16rem;
  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: ${(props) => props.theme.shadows.base};
  z-index: 9999999;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

export const Link = styled(({ isActive, isSubmenuItem, ...rest }: MobileMenuLinkProps & LinkProps) => (
  <NavLink {...rest} />
))`
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.1875rem;
  padding: ${Spacing.XS};

  ${(props) =>
    props.isSubmenuItem &&
    css`
      font-size: 1.0625rem;
      background-color: ${Color.GRAY_700};
      padding-left: ${Spacing.L};
    `};

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${props.isSubmenuItem ? props.theme.colors.grayD10 : props.theme.colors.redD10};
    `};
`;
