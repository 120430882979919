import React from 'react';
import { useTranslation } from 'react-i18next';
import { getIdAndValues } from 'utils/intl';

export const useTranslations = (prefix?: string) => {
  const { t, i18n } = useTranslation();

  const addPrefix = (idsAndValues: any[]) => (prefix ? [prefix, ...idsAndValues] : idsAndValues);

  const translate = (...idsAndValues: any[]) => {
    const { id, values } = getIdAndValues(addPrefix(idsAndValues));

    return t(id, values);
  };

  const exists = (...idsAndValues: any[]) => {
    const { id } = getIdAndValues(addPrefix(idsAndValues));

    return i18n.exists(id);
  };

  return {
    exists,
    translate,
    translateHtml: (...idsAndValues: any[]) => (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={{ __html: translate(...idsAndValues) }} />
    ),
  };
};
