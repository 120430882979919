export enum MAIN_USER_ROLES {
  /**
   * The chooser is the main admin account used to create sub-chooser accounts.
   * Has rights to everything inside a main account. There is always 1 chooser account.
   */
  CHOOSER = 'CHOOSER',

  /**
   * The subchooser also has rights to everything inside
   * a main account and can create other sub-chooser accounts.
   */
  SUB_CHOOSER = 'SUBCHOOSER',

  /**
   * A user with the finance role can view several finance related sections and pages such as invoices,
   * group allowances, usage notifications and specific end user usage. He also has the privilege to pay
   * the bills and manage the bank details.
   */
  FINANCE = 'FINANCE',

  /**
   * A user with the IT support role can manage end user details and settings. He can for example
   * activate sims, invite end users, change subscriptions, addons and contact details and
   * manage voicemail settings.
   */
  IT_SUPPORT = 'IT_SUPPORT',

  /**
   * A user with the user management role has the rights to create main users and change their access rights.
   */
  USER_MANAGEMENT = 'USER_MANAGEMENT',

  /**
   * A user with the purchase role has the rights to change subscriptions, manage addons,
   * activate sims and purchase products and services.
   */
  PURCHASE = 'PURCHASE',

  /**
   * The fixed webportal (Service Navigator) is an external portal where customers can perform
   * self service activities on various fixed products. A user with the fixed web portal role has
   * access to Service Navigator; links to this environment are visible to this user type throughout
   * the enterprise portal.
   */
  FIXED_WEB_PORTAL = 'FIXED_WEB_PORTAL',

  /**
   * A user with the account reporting role has access to monthly reports regarding usage,
   * incidents and trends. He has no access to the support section.
   */
  IWR_ACCOUNT_REPORTING = 'IWR_ACCOUT_REPORTING',

  /**
   * Extranet is a knowledge base in the portal, designed for IWR users.
   * The extranet section includes product and service descriptions, documents
   * and important customer service phone numbers.
   */
  IWR_EXTRANET = 'IWR_EXTRANET',

  /**
   * A user with the managed by portal role has access to a simplified interface of the portal.
   * This user type can have 5 different portal permissions which are also part of the IT support role.
   */
  IWR_MANAGED_BY_PORTAL = 'IWR_MANAGED_BY_PORTAL',
}
