import React from 'react';
import { useTranslations } from 'components/Intl/useTranslations';
import { useContainer } from 'unstated-next';
import { SavedOrdersContainer } from 'components/Header/savedOrders.container';
import * as S from './shoppingCart.styles';
import { HeaderIcon } from '../HeaderIcon/HeaderIcon';
import { HeaderIconIcon } from '../HeaderIcon/headerIcon.types';

export const ShoppingCart = () => {
  const { data } = useContainer(SavedOrdersContainer);
  const { translate } = useTranslations('component.header.shoppingCart');

  const count = data?.savedOrders[0]?.itemCount ?? 0;

  return (
    <S.Col col="auto">
      <S.IconWrapper hasCount={count > 0}>
        <HeaderIcon icon={HeaderIconIcon.Cart} />
        {count > 0 && <S.Count>{count}</S.Count>}
      </S.IconWrapper>
      <div>{translate('label')}</div>
    </S.Col>
  );
};
