import React from 'react';
import { useTranslations } from 'components/Intl/useTranslations';

import { ContextSwitcherSearchResultProps } from './contextSwitcherSearchResult.types';
import * as S from './contextSwitcherSearchResult.styles';

export const ContextSwitcherSearchResult = ({ results }: ContextSwitcherSearchResultProps) => {
  const { translateHtml } = useTranslations('component.contextSwitcher');

  return <S.Wrapper>{translateHtml('numberOfSearchResults', { count: results })}</S.Wrapper>;
};
