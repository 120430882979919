import React from 'react';
import { gridTheme } from '@vodafoneziggo/sandwich';
import { GridThemeProvider as BootstrapGridThemeProvider } from 'styled-bootstrap-grid';

const GridThemeProvider: React.FC = ({ children }) => {
  return (
    <BootstrapGridThemeProvider gridTheme={gridTheme}>
      <>{children}</>
    </BootstrapGridThemeProvider>
  );
};

export default GridThemeProvider;
