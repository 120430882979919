import { OidcConfig } from '@vodafoneziggo/sandwich/generic/auth';
/**
 * Get the app settings from the static JSON
 */
import { getStatic } from 'utils/api';
import { AppSettings } from './appConfig.types';

/**
 * A cached version (in memory) of the appSettings
 */
let appSettingsCache: AppSettings;

export const GetAppSettings = async (): Promise<AppSettings> => {
  if (appSettingsCache) {
    return appSettingsCache;
  }

  const responses = await Promise.all([
    getStatic('settings/app.settings.json', true),
    getStatic('settings/app.settings.json', false),
  ]);

  // Combine the two responses
  appSettingsCache = responses.reduce((acc, response) => ({ ...acc, ...response.data }), {});

  return appSettingsCache;
};

/**
 * Authentication config for the  oidc Service
 */
export const GetOidcConfig = async () => {
  const appSettings = await GetAppSettings();

  return {
    response_type: 'id_token token',
    authorisation: `${appSettings.SSO_BASE_URL}/sso/`,
    post_logout_redirect_uri: '/account/uitgelogd',
    token_type: 'Bearer',
    authorize_endpoint: `${appSettings.SSO_BASE_URL}/sso/authorize`,
    csrf_token_endpoint: `${appSettings.SSO_BASE_URL}/sso/csrf`,
    validate_token_endpoint: `${appSettings.REST_BASE_URL}/sso/check-tokens`,
    is_session_alive_endpoint: `${appSettings.REST_BASE_URL}/user-sessions`,
    upgrade_session_endpoint: `${appSettings.SSO_BASE_URL}/sso/session-upgrade`,
    redirect_uri: `${window.location.protocol}//window.location.hostname${
      window.location.port ? `:${window.location.port}` : ''
    }`,
    login_endpoint: `${appSettings.SSO_BASE_URL}/sso/j_spring_security_check`,
    logout_endpoint: `${appSettings.SSO_BASE_URL}/sso/logout`,
    restricted_redirect_uris: ['uitloggen', 'uitgelogd', 'inloggen', 'maak-een-keuze'],
    silent_refresh_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/account/assets/silent.html`,
    silent_logout_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/account/uitloggen`,
    provider_id: 'vodafone-selfservice',
    client_id: appSettings.SSO_CLIENT_ID_VODAFONE,
    // client_id: 'ziggo_selfservice',
    scope:
      'openid email hawaii_sso idm new_device_alert ziggo hawaii_backend two_factor sim_read esim_activation_code_read',
    debug: false,
  } as OidcConfig;
};
