import styled from '@vodafoneziggo/sandwich/theme';

export const Form = styled.form`
  align-items: stretch;
  display: flex;
`;

export const Submit = styled.button`
  background-color: ${(props) => props.theme.colors.white};
  border: none;
  cursor: pointer;
  width: 3rem;
`;
