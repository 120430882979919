import { MAIN_USER_ROLES } from 'config/permissions/main-user-roles.types';

/**
 * Matches if ANY of the given roles matches ANY of the current user's roles.
 * Returns a matcher function that can be injected in the roles arrays.
 * @param roles
 * @param currentUserRoles
 */
export const some = (roles: MAIN_USER_ROLES[], currentUserRoles: MAIN_USER_ROLES[]): boolean => {
  return roles.some((role) => currentUserRoles.includes(role));
};

/**
 * Matches if NONE of the given roles exist in the current user's roles
 * Returns a matcher function that can be injected in the roles arrays.
 * @param roles
 * @param currentUserRoles
 */
export const none = (roles: MAIN_USER_ROLES[], currentUserRoles: MAIN_USER_ROLES[]): boolean => {
  return !some(roles, currentUserRoles);
};

/**
 * Matches if ALL of the given roles are present in the current user's roles
 * Returns a matcher function that can be injected in the roles arrays.
 * @param roles
 * @param currentUserRoles
 */
export const all = (roles: MAIN_USER_ROLES[], currentUserRoles: MAIN_USER_ROLES[]): boolean => {
  return roles.every((role) => currentUserRoles.includes(role));
};
