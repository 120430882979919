import styled, { Spacing } from '@vodafoneziggo/sandwich/theme';

export const Wrapper = styled.section`
  background: ${(props) => props.theme.colors.redD10};
  color: ${(props) => props.theme.colors.black};
  padding: ${Spacing.XS};

  button {
    padding-top: ${Spacing.XS};
    padding-bottom: ${Spacing.XS};
  }
`;
