import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useContainer } from 'unstated-next';
import { FrontendConfigsContainer } from 'containers/Other/FrontendConfigs.container';
import { UserContext } from 'context/user/user.context';
import { hasMenuItemPermission, getMenuItemUrl } from 'utils/location';
import { IMenuItem } from './useMenu.types';
import { useMenu } from './useMenu';

export const useSubmenu = () => {
  const { currentMenuItem } = useMenu();
  const [filteredSubmenuItems, setFilteredSubmenuItems] = useState<IMenuItem[]>([]);
  const [currentSubmenuItem, setCurrentSubmenuItem] = useState<IMenuItem>();
  const { v2Config } = useContainer(FrontendConfigsContainer);

  const location = useLocation();
  const {
    state: {
      enterprise: { activeContext },
    },
  } = useContext(UserContext);

  // Determine and filter submenu items on permissions, also sets the url property
  useEffect(() => {
    // No submenu for current main menu, make sure to empty the state
    if (!currentMenuItem?.submenu) return setFilteredSubmenuItems([]);
    // Active context not determined yet, make sure to empty the state
    if (!activeContext?.id) return setFilteredSubmenuItems([]);
    // V2 config not loaded yet, make sure to empty the state
    if (!v2Config) return setFilteredSubmenuItems([]);

    const submenuItems = currentMenuItem?.submenu.filter((item) => hasMenuItemPermission(activeContext, item)) ?? [];

    setFilteredSubmenuItems(
      submenuItems.map((submenuItem) => ({ ...submenuItem, url: getMenuItemUrl(activeContext, v2Config, submenuItem) }))
    );
  }, [currentMenuItem?.submenu, activeContext, v2Config]);

  // Determine current submenu item
  useEffect(() => {
    if (!filteredSubmenuItems) {
      setCurrentSubmenuItem(undefined);

      return;
    }

    const currentBaseRoute = location.pathname.split('/')[4];
    const currentSubRoute = location.pathname.split('/')[5];
    const subRouteRegExp = new RegExp(`:context_id/${currentBaseRoute}/${currentSubRoute}`);

    setCurrentSubmenuItem(filteredSubmenuItems.find((item) => subRouteRegExp.test(item.stateObj.completeUrl)));
  }, [location, filteredSubmenuItems]);

  return { submenuItems: filteredSubmenuItems, currentSubmenuItem, currentMenuItem };
};
