import React from 'react';
import { EnterpriseContextType } from '@vodafoneziggo/sandwich/models';
import { CONTEXT_ROLES } from 'config/permissions/context-roles.types';
import { ContextSwitcherIcon } from '../ContextSwitcherIcon/ContextSwitcherIcon';
import { ContextSwitcherIconIcon } from '../ContextSwitcherIcon/contextSwitcherIcon.types';
import { ContextSwitcherDropdownLevelIconProps } from './contextSwitcherDropdownLevelIcon.types';

export const ContextSwitcherDropdownLevelIcon = ({
  role,
  type,
  mainUserBillingCustomers,
}: ContextSwitcherDropdownLevelIconProps) => {
  if (type === EnterpriseContextType.ACCOUNT) {
    return null;
  }

  if (role === CONTEXT_ROLES.MAIN_USER) {
    if (mainUserBillingCustomers && mainUserBillingCustomers > 1) {
      return <ContextSwitcherIcon icon={ContextSwitcherIconIcon.TreeWithMarkedBottom} />;
    }

    if (mainUserBillingCustomers === 1) {
      return <ContextSwitcherIcon icon={ContextSwitcherIconIcon.Building} />;
    }

    return null;
  }

  if (role === CONTEXT_ROLES.END_USER) {
    return <ContextSwitcherIcon icon={ContextSwitcherIconIcon.Person} />;
  }

  return null;
};
