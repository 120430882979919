import React, { useEffect } from 'react';
import { ModalContext } from '@vodafoneziggo/sandwich/components/modal';
import { Modal } from 'components/Modal/Modal';
import { Button, CORPORATEMIDCOLORED_WARNINGMIDCOLORED } from '@vodafoneziggo/sandwich';
import { ButtonVariant } from '@vodafoneziggo/sandwich/components/button';
import { useTranslations } from 'components/Intl/useTranslations';
import { ExistsFunction } from 'i18next';
import { trackError } from 'utils/ddm';
import { ClientErrorProps, ErrorTranslationData } from './clientError.types';
import { Exception } from '../graphqlContext.types';

const getErrorData = (exception: Exception): ErrorTranslationData => ({
  idmErrorCode: exception.idmErrorCode,
  requestValidationError: exception.requestValidationErrors?.[0]?.toLowerCase(),
  itemValidationErrors: (exception.itemValidationErrors || []).map((error) => error.item_key.toLowerCase()),
  bslServerErrorCode: exception.bslServerErrorCodes?.[0]?.toLowerCase(),
  mosaServerErrorCode: exception.mosaServerErrorCodes?.[0]?.toLowerCase(),
});

const errorKey = (errorData: ErrorTranslationData, exists: ExistsFunction) => {
  if (errorData.idmErrorCode) {
    const key = `kahunaBackendErrors.idm.${errorData.idmErrorCode.toLowerCase()}`;

    return exists(key) ? key : 'kahunaBackendErrors.idm.genericFallback';
  }

  if (errorData.requestValidationError) {
    const key = `kahunaBackendErrors.request.${errorData.requestValidationError}`;

    return exists(key) ? key : 'kahunaBackendErrors.request.genericFallback';
  }

  if (errorData.itemValidationErrors.length) return 'kahunaBackendErrors.generic.formHasErrors';

  if (errorData.bslServerErrorCode) {
    const key = `kahunaBackendErrors.bsl.${errorData.bslServerErrorCode.toLowerCase()}`;

    return exists(key) ? key : 'kahunaBackendErrors.bsl.genericFallback';
  }

  if (errorData.mosaServerErrorCode) {
    const key = `kahunaBackendErrors.mosa.${errorData.mosaServerErrorCode.toLowerCase()}`;

    return exists(key) ? key : 'kahunaBackendErrors.mosa.genericFallback';
  }

  return 'kahunaBackendErrors.generic.fallback';
};

export const ClientError = ({ exception, onClose }: ClientErrorProps) => {
  const { translate, translateHtml, exists } = useTranslations('component.graphqlClientError');

  const errorData = getErrorData(exception);
  const error = errorKey(errorData, exists);
  const message = translateHtml(error, errorData);

  useEffect(() => {
    // Send the error to DDM
    trackError(exception.status || 0, error);
  }, [exception, error]);

  return (
    <Modal
      isVisible
      onClose={onClose}
      headerTitle={translate('headerTitle')}
      headerIcon={CORPORATEMIDCOLORED_WARNINGMIDCOLORED}
      title={translate('modalTitle')}
      footer={
        <ModalContext.Consumer>
          {(modal) => (
            <Button variant={ButtonVariant.DARK} isOutlined onClick={() => modal.close()}>
              {translate('close')}
            </Button>
          )}
        </ModalContext.Consumer>
      }
    >
      {message}
    </Modal>
  );
};
