/* eslint-disable */

/**
 * Dimelo Chat Helper
 * This file is supplied by Dimelo and should not be edited directly
 */
var DimeloChatHelper = (window.DimeloChatHelper = (function (undefined) {
  var helper = {};

  helper.localStorage = window.localStorage;

  helper._chatq = window._chatq || [];

  helper.getIdentity = function () {
    var identity = JSON.parse(this.localStorage.getItem('chat_identity'));

    if (identity && new Date().getTime() < identity.timestamp) {
      return identity;
    } else {
      return null;
    }
  };

  helper.saveIdentity = function (identity) {
    if (identity) {
      var expire_date = new Date();

      expire_date.setHours(expire_date.getHours() + 2);

      identity.timestamp = new Date(expire_date).getTime();

      this.localStorage.setItem('chat_identity', JSON.stringify(identity));

      this.setIdentity();
    }
  };

  helper.setIdentity = function () {
    var identity = this.getIdentity();

    if (identity) {
      delete identity.timestamp;

      this._chatq.push(['_setIdentity', identity]);

      window._chatq = this._chatq;
    }
  };

  return helper;
})());
