import styled, { Breakpoint, Spacing } from '@vodafoneziggo/sandwich/theme';
import { Col as GridCol } from 'styled-bootstrap-grid';
import { down } from 'styles/utils/mediaqueries';

export const Col = styled(GridCol)`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding-left: 0;

  ${down(Breakpoint.SM)`
    padding-right: 2.3rem;
  `}
`;

export const IconWrapper = styled.div<{ hasCount: boolean }>`
  display: flex;
  margin-right: ${({ hasCount }) => (hasCount ? Spacing.S : Spacing.XS)};
`;

export const Count = styled.div`
  position: absolute;
  left: 0.75rem;
  top: -0.125rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.primary};
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.625rem;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  /* The sandwich scales aren't small enough for this one */
  padding: 0 0.2rem;
`;
