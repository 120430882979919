import { UserContext } from 'context/user/user.context';
import { useContext, useEffect, useState } from 'react';
import { getUrlBase } from 'utils/location';

/**
 * Provides the V2 url base for the currently active context
 */
export const useUrlBase = () => {
  const [urlBase, setUrlBase] = useState<string>();
  const {
    state: {
      enterprise: { activeContext },
    },
  } = useContext(UserContext);

  useEffect(() => {
    setUrlBase(activeContext ? getUrlBase(activeContext) : undefined);
  }, [activeContext]);

  return urlBase;
};
