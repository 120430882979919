import { NotFound } from 'pages/generic/NotFound/NotFound';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation, RouteProps } from 'react-router-dom';
import { UserProvider } from 'context/user/user.context';
import { AuthProvider } from 'context/auth';
import BaseLayout from 'components/BaseLayout';
import { ChooseContext } from 'pages/generic/ChooseContext';
import { LoadingIcon } from 'components/icons/LoadingIcon/LoadingIcon';
import { redirectToLegacy } from 'utils/location';
import { PageTranslationsContainer } from 'containers/Other/PageTranslations.container';
import { LayoutProvider } from 'context/layout/layout.context';
import { Chat } from 'components/Chat/Chat';
import { Tagging } from './components/Tagging/Tagging';

const Account = React.lazy(() => import('./account/routes'));
const Customer = React.lazy(() => import('./customer/routes'));

// Default to legacy overview.
const RedirectToOverview = () => {
  const location = useLocation();

  useEffect(() => {
    redirectToLegacy();
  }, [location.pathname]);

  return null;
};

const Routes = ({ path }: RouteProps) => (
  <AuthProvider>
    <UserProvider>
      <Tagging />
      <LayoutProvider>
        <BaseLayout>
          <React.Suspense fallback={<LoadingIcon />}>
            <Chat />
            <Switch>
              <Route path={`${path}/`} exact>
                <RedirectToOverview />
              </Route>

              <Route path={`${path}/maak-een-keuze`} exact>
                <PageTranslationsContainer.Provider initialState="chooseContext">
                  <ChooseContext />
                </PageTranslationsContainer.Provider>
              </Route>

              <Route path={`${path}/account/:contextId`}>
                <Account path={`${path}/account/:contextId`} />
              </Route>

              <Route path={`${path}/organisatie/:contextId`}>
                <Customer path={`${path}/organisatie/:contextId`} />
              </Route>

              <Route path={`${path}/oeps`}>
                {() => {
                  // Throwing an error makes the ErrorBoundary catch it, which in turn shows the oeps page
                  throw new Error('Oeps');
                }}
              </Route>
              <Route component={NotFound} />
            </Switch>
          </React.Suspense>
        </BaseLayout>
      </LayoutProvider>
    </UserProvider>
  </AuthProvider>
);

export default Routes;
