/**
 * Breakpoints according to the Sandwich WS10 implementation, not matching WS10 spec at the time of writing
 */
export enum Breakpoint {
  XS = 0,
  SM = 480,
  MD = 768,
  L = 1024,
  XL = 1200,
}

/**
 * Object which contains all breakpoints as key: number entries
 */
export const breakpointValues = Object.fromEntries(
  Object.entries(Breakpoint)
    .filter((e) => typeof e[1] === 'number')
    .map((e) => e as [string, number])
);
