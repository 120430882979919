import React, { useContext, useRef } from 'react';
import { useContainer } from 'unstated-next';
import { UserContext } from 'context/user/user.context';
import { ContextSwitcherContainer } from 'components/Header/contextSwitcher.container';
import { Icon, MY_ICON_USER_MEDIUM, CORPORATE_CHEVRONDOWN } from '@vodafoneziggo/sandwich';
import { useUser } from 'hooks/useUser';

import { HeaderIcon } from '../HeaderIcon/HeaderIcon';
import { HeaderIconIcon } from '../HeaderIcon/headerIcon.types';
import { ContextSwitcherTopLevelIcon } from '../ContextSwitcherTopLevelIcon/ContextSwitcherTopLevelIcon';
import { ContextSwitcherDropdown } from '../ContextSwitcherDropdown/ContextSwitcherDropdown';
import { ContextDetails } from '../ContextDetails/ContextDetails';

import * as S from './contextSwitcher.styles';

export const ContextSwitcher = () => {
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const { isVisible, setIsVisible } = useContainer(ContextSwitcherContainer);

  const { isIwr } = useUser();

  const {
    mainUserBillingCustomers,
    state: {
      enterprise: { contexts, activeContext },
    },
  } = useContext(UserContext);

  /**
   * Handles visibility toggling of the dropdown. It prevents DOM nodes which are part of the search
   * container/flow from closing the dropdown when it shouldn't be closed.
   *
   * @param event Click event
   */
  const onClickHandler = (event: React.MouseEvent): void => {
    const target = event.target as HTMLElement;

    // Exclude DOM nodes that shouldn't close the dropdown.
    if (searchContainerRef?.current?.contains(target)) {
      return;
    }

    setIsVisible(!isVisible);
  };

  if (!contexts) {
    return null;
  }

  return (
    <S.ContextSwitcher onClick={onClickHandler} role="button">
      <S.Button>
        <ContextSwitcherTopLevelIcon
          isIwr={isIwr}
          mainUserBillingCustomers={mainUserBillingCustomers}
          role={activeContext?.role}
          type={activeContext?.type}
        />
        <ContextDetails id={activeContext?.id} name={activeContext?.name} role={activeContext?.role} isTopLevel />
        <S.MenuIconWrapper isRotated={isVisible}>
          <S.MenuIcon isRotated={isVisible} icon={CORPORATE_CHEVRONDOWN} height="1rem" width="1rem" />
        </S.MenuIconWrapper>
      </S.Button>
      <S.TopRowButton>
        <Icon icon={MY_ICON_USER_MEDIUM} width="1.3rem" height="1.3rem" />
        <S.TopRowName>{activeContext?.name}</S.TopRowName>
        <S.TopRowMenuIcon isRotated={isVisible}>
          <HeaderIcon icon={HeaderIconIcon.CaretDown} />
        </S.TopRowMenuIcon>
      </S.TopRowButton>
      {isVisible && <ContextSwitcherDropdown searchContainerRef={searchContainerRef} />}
    </S.ContextSwitcher>
  );
};
