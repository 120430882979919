import React from 'react';
import * as S from './loading-icon.styles';

export const LoadingIcon = () => {
  return (
    <S.LoadingIconOverlay>
      <S.LoadingIcon viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1.24773,0,0,1.24773,-2.26415,-0.973359)">
          <path d="M16.622,1.684C14.36,2.165 12.724,4.172 12.708,6.484L12.708,6.648C13.616,6.837 14.484,7.186 15.272,7.677C15.924,8.073 16.491,8.595 16.938,9.213L17.034,9.35C17.352,9.822 17.597,10.34 17.762,10.887C17.93,11.453 18.024,12.038 18.042,12.628L18.042,12.86C18.047,13.872 17.812,14.871 17.356,15.775L17.37,15.783C16.953,16.583 16.368,17.285 15.656,17.839C14.296,18.902 12.567,19.378 10.856,19.163C10.508,19.12 10.164,19.049 9.828,18.95C8.25,18.484 6.891,17.467 6,16.084C5.618,15.485 5.325,14.833 5.13,14.15L5.089,13.993C4.986,13.603 4.913,13.207 4.869,12.806C4.825,12.394 4.812,11.979 4.829,11.565L4.829,11.442C4.862,10.859 4.949,10.28 5.089,9.714C5.627,7.611 6.809,5.728 8.469,4.33C9.447,3.506 10.558,2.855 11.754,2.404C12.09,2.274 12.44,2.164 12.735,2.068L13.345,1.903C13.846,1.779 14.353,1.688 14.867,1.629C15.37,1.566 15.879,1.566 16.383,1.629L16.622,1.684Z" />
        </g>
      </S.LoadingIcon>
    </S.LoadingIconOverlay>
  );
};
