import styled, { keyframes } from '@vodafoneziggo/sandwich/theme';

export const LoadingIconAnimation = keyframes`
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 28;
  } 

  50% {
    stroke-dashoffset: 28;
    stroke-dasharray: 28;
  }
  
  100% {
    stroke-dashoffset: 56;
    stroke-dasharray: 28;
  }
`;

export const LoadingIcon = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  width: 4rem;
  height: 4rem;
  fill: none;
  stroke: ${(props) => props.theme.colors.primary};
  stroke-dashoffset: 0;
  stroke-dasharray: 24;
  stroke-width: 1px;
  animation: ${LoadingIconAnimation} 1.5s infinite linear;
`;

export const LoadingIconOverlay = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
