// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context';
import { OidcUtil } from '@vodafoneziggo/sandwich/generic/auth';
import { ApiError } from 'utils/api/api.types';

/**
 * This Link will add the auth token and context contact id to the request headers
 */
export const authLink = setContext(async (_, { headers }) => {
  try {
    await OidcUtil.attemptTokenRefresh();

    // Get the authorization header
    const authorization = OidcUtil.getAuthHeader();

    if (!authorization) {
      throw Error(ApiError.INVALID_AUTH_TOKEN);
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  } catch (e) {
    // Throw error to handle in UI
    throw new Error(ApiError.INVALID_AUTH_TOKEN);
  }
});
