import Axios from 'axios';

export enum BASE_PATH {
  STATIC = '/zakelijk/my/v2/static/',
  CONSUMER_STATIC = '/my/static/',
  FE_CONFIGS = '/fe-configs/',
}

/**
 * Creating Axios instance
 * This is needed for testing
 */
export const fileApiInstance = Axios.create();

/**
 * GET Request from statics endpoint
 * @param url relative url to static folder
 * @param fromConsumer fetch a consumer static resource instead of enterprise, default false
 */
export const getStatic = (url: string, fromConsumer = false) =>
  fileApiInstance({ url: `${fromConsumer ? BASE_PATH.CONSUMER_STATIC : BASE_PATH.STATIC}${url}` });

/**
 * GET Request from fe-configs endpoint
 * @param url relative url to vf-frontend-configs repository
 */
export const getFromFeConfigs = (url: string) => fileApiInstance({ url: `${BASE_PATH.FE_CONFIGS}${url}` });
