// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context';
import { GetAppSettings } from 'config/app.config';

/**
 * This link will enable setting the URI based on a promise
 */
export const uriLink = setContext(async () => {
  const settings = await GetAppSettings();

  return { uri: settings.MY_API_ENDPOINT };
});
