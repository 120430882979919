import { createContainer } from 'unstated-next';
import { useState } from 'react';

const useContextSwitcher = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSearchInitialState, setIsSearchInitialState] = useState(true);

  return {
    isVisible,
    setIsVisible,
    isSearchInitialState,
    setIsSearchInitialState,
  };
};

export const ContextSwitcherContainer = createContainer(useContextSwitcher);
